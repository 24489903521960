import React, { useState, useEffect, useRef } from "react";

import useMediaQuery from '@mui/material/useMediaQuery';
import './Homepage.css'
import './../../components/css/TextStyle.css'
import './../../components/css/ColorStyle.css'
import HomeClient from "../../components/HomeClient";
import HomeFeature from "../../components/HomeFeature";
import imageFeature1 from './../../assets/bg/feature1.jpg'
import imageFeature2 from './../../assets/bg/feature2.jpg'
import imageFeature3 from './../../assets/bg/feature3.jpg'
import imageFeature4 from './../../assets/bg/feature4.jpg'
import imageFeature5 from './../../assets/bg/feature5.jpg'
import polygon1 from './../../assets/Icon/polygon-left.png'
import polygon2 from './../../assets/Icon/polygon-right.png'
// import imageProduct1 from './../../assets/'

import Carousel from 'react-material-ui-carousel'
import { Paper, Button } from '@mui/material'
import Box from '@mui/material/Box';
import { Grid } from "@mui/material";
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

import QuestionSection from "../../components/QuestionSection";
import HomeSoftware from "../../components/HomeSoftware";

const Homepage = () => {

    // HomeClient Props
    const matches = useMediaQuery("(min-width:600px)");
    const [filterClient, setFilterClient] = useState(0)
    const [dataClientTemp, setDataClientTemp] = useState([])
    const [newKategoriData, setNewKategoriData] = useState()
    const [filtered, setFiltered] = useState(false)
    const [alignment, setAlignment] = useState('web');
    const [logoClientDataDummy, setLogoClientDataDummy] = useState([
        {
            "id": 1,
            "no_urut": 100,
            "kategori_id": 1,
            "link": "",
            "gambar": "ALM.jpg",
            "type": "Vertikal",
            "new_flag": 0,
            "created_at": "2021-02-22T07:30:53",
            "updated_at": "2021-04-08T11:46:50",
            "deleted_at": null
        },
        {
            "id": 2,
            "no_urut": 100,
            "kategori_id": 1,
            "link": "",
            "gambar": "Assa Land.png",
            "type": "Vertikal",
            "new_flag": 0,
            "created_at": "2021-02-22T07:31:17",
            "updated_at": "2021-04-08T11:47:08",
            "deleted_at": null
        },
        {
            "id": 3,
            "no_urut": 100,
            "kategori_id": 1,
            "link": "",
            "gambar": "Assa Land_Marvell city.jpg",
            "type": "Vertikal",
            "new_flag": 0,
            "created_at": "2021-02-22T07:31:58",
            "updated_at": "2021-04-08T11:47:08",
            "deleted_at": null
        },
        {
            "id": 4,
            "no_urut": 15,
            "kategori_id": 1,
            "link": "",
            "gambar": "Discovery Mall Bali.jpeg",
            "type": "Vertikal",
            "new_flag": 0,
            "created_at": "2021-02-22T07:32:12",
            "updated_at": "2023-03-16T13:39:34",
            "deleted_at": "2023-03-16T13:39:34"
        },
        {
            "id": 5,
            "no_urut": 10,
            "kategori_id": 0,
            "link": "",
            "gambar": "Gunacipta Multirasa.png",
            "type": "Vertikal",
            "new_flag": 0,
            "created_at": "2021-02-22T07:32:31",
            "updated_at": "2021-09-09T10:40:32",
            "deleted_at": null
        },
        {
            "id": 6,
            "no_urut": 18,
            "kategori_id": 0,
            "link": "",
            "gambar": "LSPP.png",
            "type": "Vertikal",
            "new_flag": 0,
            "created_at": "2021-02-22T07:32:52",
            "updated_at": "2021-09-09T10:40:56",
            "deleted_at": null
        },
        {
            "id": 7,
            "no_urut": 100,
            "kategori_id": 2,
            "link": "",
            "gambar": "LTC Glodok.png",
            "type": "Vertikal",
            "new_flag": 0,
            "created_at": "2021-02-22T07:40:45",
            "updated_at": "2021-04-08T11:48:55",
            "deleted_at": null
        },
        {
            "id": 8,
            "no_urut": 11,
            "kategori_id": 2,
            "link": "",
            "gambar": "Mangga Dua Square.jpg",
            "type": "Vertikal",
            "new_flag": 0,
            "created_at": "2021-02-22T07:41:20",
            "updated_at": "2021-09-09T10:40:35",
            "deleted_at": null
        },
        {
            "id": 9,
            "no_urut": 100,
            "kategori_id": 2,
            "link": "",
            "gambar": "Mediterania Gajah Mada Residence.jpg",
            "type": "Vertikal",
            "new_flag": 0,
            "created_at": "2021-02-22T07:41:57",
            "updated_at": "2021-04-08T11:47:08",
            "deleted_at": null
        },
        {
            "id": 10,
            "no_urut": 13,
            "kategori_id": 2,
            "link": "",
            "gambar": "Menara Maritim.jpg",
            "type": "Vertikal",
            "new_flag": 0,
            "created_at": "2021-02-22T07:42:08",
            "updated_at": "2021-09-09T10:40:40",
            "deleted_at": null
        },
        {
            "id": 11,
            "no_urut": 100,
            "kategori_id": 0,
            "link": "",
            "gambar": "Modernland Realty Tbk.jpg",
            "type": "Vertikal",
            "new_flag": 0,
            "created_at": "2021-02-22T07:43:02",
            "updated_at": "2021-04-08T11:47:08",
            "deleted_at": null
        },
        {
            "id": 12,
            "no_urut": 100,
            "kategori_id": 3,
            "link": "",
            "gambar": "PCPD (PBI).png",
            "type": "Vertikal",
            "new_flag": 0,
            "created_at": "2021-02-22T07:43:16",
            "updated_at": "2021-04-08T11:47:08",
            "deleted_at": "2021-03-08T11:09:38"
        },
        {
            "id": 13,
            "no_urut": 100,
            "kategori_id": 3,
            "link": "",
            "gambar": "Plaza Jambu Dua Bogor.png",
            "type": "Horizontal",
            "new_flag": 0,
            "created_at": "2021-02-22T07:43:28",
            "updated_at": "2021-04-08T11:47:08",
            "deleted_at": null
        },
        {
            "id": 14,
            "no_urut": 100,
            "kategori_id": 3,
            "link": "",
            "gambar": "PP Urban.png",
            "type": "Vertikal",
            "new_flag": 0,
            "created_at": "2021-02-22T07:43:43",
            "updated_at": "2021-04-08T11:47:08",
            "deleted_at": "2021-03-08T10:47:13"
        },
        {
            "id": 15,
            "no_urut": 9,
            "kategori_id": 4,
            "link": "",
            "gambar": "pp0.png",
            "type": "Horizontal",
            "new_flag": 0,
            "created_at": "2021-02-22T07:44:21",
            "updated_at": "2021-09-09T10:40:28",
            "deleted_at": null
        },
        {
            "id": 16,
            "no_urut": 100,
            "kategori_id": 4,
            "link": "",
            "gambar": "Prima Bangun Investama.png",
            "type": "Horizontal",
            "new_flag": 0,
            "created_at": "2021-02-22T07:44:33",
            "updated_at": "2021-04-08T11:47:08",
            "deleted_at": null
        },
        {
            "id": 17,
            "no_urut": 100,
            "kategori_id": 0,
            "link": "",
            "gambar": "PT Pardika Wisthi Sarana.png",
            "type": "Vertikal",
            "new_flag": 0,
            "created_at": "2021-02-22T07:44:58",
            "updated_at": "2021-09-09T10:41:12",
            "deleted_at": null
        },
        {
            "id": 18,
            "no_urut": 100,
            "kategori_id": 4,
            "link": "",
            "gambar": "Pulo Gadung Trade Center.jpeg",
            "type": "Vertikal",
            "new_flag": 0,
            "created_at": "2021-02-22T07:45:12",
            "updated_at": "2021-04-08T11:47:08",
            "deleted_at": null
        },
        {
            "id": 19,
            "no_urut": 100,
            "kategori_id": 5,
            "link": "",
            "gambar": "Sampoerna Strategic Square.png",
            "type": "Horizontal",
            "new_flag": 0,
            "created_at": "2021-02-22T07:45:50",
            "updated_at": "2021-04-08T11:47:08",
            "deleted_at": null
        },
        {
            "id": 20,
            "no_urut": 100,
            "kategori_id": 5,
            "link": "",
            "gambar": "Southcity.png",
            "type": "Horizontal",
            "new_flag": 0,
            "created_at": "2021-02-22T07:46:03",
            "updated_at": "2021-04-08T11:47:08",
            "deleted_at": null
        },
        {
            "id": 21,
            "no_urut": 100,
            "kategori_id": 5,
            "link": "",
            "gambar": "Surya Cipta Swadaya.png",
            "type": "Horizontal",
            "new_flag": 0,
            "created_at": "2021-02-22T07:46:15",
            "updated_at": "2023-03-09T23:37:49",
            "deleted_at": "2023-03-09T23:37:49"
        },
        {
            "id": 22,
            "no_urut": 16,
            "kategori_id": 5,
            "link": "",
            "gambar": "Tangcity Mall.png",
            "type": "Vertikal",
            "new_flag": 0,
            "created_at": "2021-02-22T07:46:27",
            "updated_at": "2022-08-29T09:59:02",
            "deleted_at": "2022-08-29T09:59:02"
        },
        {
            "id": 23,
            "no_urut": 6,
            "kategori_id": 6,
            "link": "",
            "gambar": "The East Tower.png",
            "type": "Vertikal",
            "new_flag": 0,
            "created_at": "2021-02-22T07:47:32",
            "updated_at": "2021-09-09T10:40:20",
            "deleted_at": null
        },
        {
            "id": 24,
            "no_urut": 100,
            "kategori_id": 6,
            "link": "",
            "gambar": "Wisma Nusantara.png",
            "type": "Vertikal",
            "new_flag": 0,
            "created_at": "2021-02-22T07:47:49",
            "updated_at": "2021-04-26T14:21:47",
            "deleted_at": null
        },
    ])
    const [kategoriClientDataDummy, setKategoriClientDataDummy] = useState([
        {
            "id": 1,
            "kategori": "Landed Housing",
            "created_at": "2021-02-20T08:28:31",
            "updated_at": "2021-02-20T15:32:10",
            "deleted_at": null
        },
        {
            "id": 2,
            "kategori": "Apartment",
            "created_at": "2021-02-20T08:28:43",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 3,
            "kategori": "Mall",
            "created_at": "2021-02-20T08:28:43",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 4,
            "kategori": "Office Tower",
            "created_at": "2021-02-20T08:28:43",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 5,
            "kategori": "Industrial Estate",
            "created_at": "2021-02-20T08:28:43",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 6,
            "kategori": "Mixed Used Development",
            "created_at": "2021-02-20T08:28:43",
            "updated_at": null,
            "deleted_at": null
        }
    ])
    const [manyClientKategoriDataDummy, setManyClientKategoriDataDummy] = useState([
        {
            "id": 1,
            "client_id": 1,
            "kategori_id": 1,
            "created_at": "2021-02-25T01:42:01",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 2,
            "client_id": 2,
            "kategori_id": 1,
            "created_at": "2021-02-25T01:42:01",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 3,
            "client_id": 3,
            "kategori_id": 1,
            "created_at": "2021-02-25T01:42:01",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 6,
            "client_id": 6,
            "kategori_id": 1,
            "created_at": "2021-02-25T01:42:01",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 7,
            "client_id": 7,
            "kategori_id": 2,
            "created_at": "2021-02-25T01:42:01",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 8,
            "client_id": 8,
            "kategori_id": 2,
            "created_at": "2021-02-25T01:42:01",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 15,
            "client_id": 15,
            "kategori_id": 4,
            "created_at": "2021-02-25T01:42:01",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 16,
            "client_id": 16,
            "kategori_id": 4,
            "created_at": "2021-02-25T01:42:01",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 17,
            "client_id": 17,
            "kategori_id": 4,
            "created_at": "2021-02-25T01:42:01",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 26,
            "client_id": 26,
            "kategori_id": 6,
            "created_at": "2021-02-25T01:42:01",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 27,
            "client_id": 28,
            "kategori_id": 1,
            "created_at": "2021-02-25T01:42:01",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 29,
            "client_id": 30,
            "kategori_id": 1,
            "created_at": "2021-02-25T06:37:03",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 30,
            "client_id": 31,
            "kategori_id": 1,
            "created_at": "2021-02-25T06:39:23",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 31,
            "client_id": 32,
            "kategori_id": 1,
            "created_at": "2021-02-25T06:48:06",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 32,
            "client_id": 33,
            "kategori_id": 5,
            "created_at": "2021-02-25T07:10:10",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 33,
            "client_id": 34,
            "kategori_id": 1,
            "created_at": "2021-02-25T07:16:41",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 35,
            "client_id": 9,
            "kategori_id": 1,
            "created_at": "2021-02-26T06:29:15",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 36,
            "client_id": 10,
            "kategori_id": 1,
            "created_at": "2021-02-26T06:29:26",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 41,
            "client_id": 40,
            "kategori_id": 2,
            "created_at": "2021-02-26T09:01:28",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 42,
            "client_id": 41,
            "kategori_id": 1,
            "created_at": "2021-02-26T09:06:08",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 45,
            "client_id": 44,
            "kategori_id": 1,
            "created_at": "2021-03-03T02:39:45",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 47,
            "client_id": 46,
            "kategori_id": 1,
            "created_at": "2021-03-03T03:06:13",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 48,
            "client_id": 47,
            "kategori_id": 3,
            "created_at": "2021-03-03T03:13:19",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 50,
            "client_id": 11,
            "kategori_id": 1,
            "created_at": "2021-03-03T04:01:40",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 51,
            "client_id": 29,
            "kategori_id": 5,
            "created_at": "2021-03-04T06:18:55",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 52,
            "client_id": 29,
            "kategori_id": 6,
            "created_at": "2021-03-04T06:18:55",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 53,
            "client_id": 39,
            "kategori_id": 2,
            "created_at": "2021-03-04T10:22:27",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 54,
            "client_id": 39,
            "kategori_id": 6,
            "created_at": "2021-03-04T10:22:27",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 58,
            "client_id": 19,
            "kategori_id": 4,
            "created_at": "2021-03-08T03:49:30",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 59,
            "client_id": 23,
            "kategori_id": 2,
            "created_at": "2021-03-08T03:50:04",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 60,
            "client_id": 20,
            "kategori_id": 1,
            "created_at": "2021-03-08T03:53:49",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 61,
            "client_id": 13,
            "kategori_id": 2,
            "created_at": "2021-03-08T03:54:07",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 63,
            "client_id": 24,
            "kategori_id": 1,
            "created_at": "2021-03-08T04:07:52",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 64,
            "client_id": 25,
            "kategori_id": 4,
            "created_at": "2021-03-08T04:08:13",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 65,
            "client_id": 18,
            "kategori_id": 1,
            "created_at": "2021-03-08T04:10:18",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 68,
            "client_id": 35,
            "kategori_id": 2,
            "created_at": "2021-03-08T06:06:27",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 69,
            "client_id": 49,
            "kategori_id": 1,
            "created_at": "2021-04-08T04:23:47",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 72,
            "client_id": 52,
            "kategori_id": 3,
            "created_at": "2021-04-08T04:29:18",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 73,
            "client_id": 53,
            "kategori_id": 3,
            "created_at": "2021-04-08T04:30:41",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 74,
            "client_id": 54,
            "kategori_id": 2,
            "created_at": "2021-04-08T04:31:48",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 76,
            "client_id": 56,
            "kategori_id": 2,
            "created_at": "2021-05-18T03:01:34",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 77,
            "client_id": 5,
            "kategori_id": 6,
            "created_at": "2021-05-19T02:32:37",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 78,
            "client_id": 57,
            "kategori_id": 1,
            "created_at": "2021-07-28T08:15:40",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 79,
            "client_id": 58,
            "kategori_id": 2,
            "created_at": "2021-09-09T03:11:16",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 80,
            "client_id": 59,
            "kategori_id": 2,
            "created_at": "2022-01-26T07:51:02",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 81,
            "client_id": 60,
            "kategori_id": 6,
            "created_at": "2022-01-26T07:52:49",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 82,
            "client_id": 61,
            "kategori_id": 3,
            "created_at": "2022-01-26T07:55:25",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 83,
            "client_id": 62,
            "kategori_id": 3,
            "created_at": "2023-03-07T16:40:23",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 84,
            "client_id": 63,
            "kategori_id": 4,
            "created_at": "2023-03-07T16:41:33",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 87,
            "client_id": 64,
            "kategori_id": 5,
            "created_at": "2023-03-07T16:44:51",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 89,
            "client_id": 66,
            "kategori_id": 5,
            "created_at": "2023-03-07T16:46:55",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 90,
            "client_id": 67,
            "kategori_id": 5,
            "created_at": "2023-03-07T16:47:46",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 96,
            "client_id": 73,
            "kategori_id": 4,
            "created_at": "2023-03-09T16:33:54",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 102,
            "client_id": 65,
            "kategori_id": 2,
            "created_at": "2023-03-09T16:37:28",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 103,
            "client_id": 50,
            "kategori_id": 6,
            "created_at": "2023-03-09T16:37:39",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 104,
            "client_id": 79,
            "kategori_id": 3,
            "created_at": "2023-03-16T06:38:15",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 105,
            "client_id": 80,
            "kategori_id": 1,
            "created_at": "2023-03-16T06:42:07",
            "updated_at": null,
            "deleted_at": null
        }
    ])
    const [contentCarousel, setContentCarousel] = useState({})
    const [nextCarousel, setNextCarousel] = useState(false)
    const [currCar, setCurrCar] = useState(0)
    useEffect(()=>{
        setFiltered(false)
    },[])

    const styles = {
        paperContainer1: {
            backgroundImage: `linear-gradient(rgba(29, 32, 32, 0.64), rgba(29, 32, 32, 0.64)), url(${imageFeature1})`,
            // backgroundColor: 'rgba(0, 0, 0, 0.8)',
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
        },
        paperContainer2: {
            backgroundImage: `linear-gradient(rgba(29, 32, 32, 0.64), rgba(29, 32, 32, 0.64)), url(${imageFeature2})`,
            // backgroundColor: 'rgba(0, 0, 0, 0.8)',
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
        },
        paperContainer3: {
            backgroundImage: `linear-gradient(rgba(29, 32, 32, 0.64), rgba(29, 32, 32, 0.64)), url(${imageFeature3})`,
            // backgroundColor: 'rgba(0, 0, 0, 0.8)',
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
        },
        paperContainer4: {
            backgroundImage: `linear-gradient(rgba(29, 32, 32, 0.64), rgba(29, 32, 32, 0.64)), url(${imageFeature4})`,
            // backgroundColor: 'rgba(0, 0, 0, 0.8)',
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
        },
        paperContainer5: {
            backgroundImage: `linear-gradient(rgba(29, 32, 32, 0.64), rgba(29, 32, 32, 0.64)), url(${imageFeature5})`,
            // backgroundColor: 'rgba(0, 0, 0, 0.8)',
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
        }
    };
    var items = [
        {
            id: 0,
            title: "AdvancedProperty+",
            image: 'imageFeature1',
            description: "The best total integrated and proven ERP software solution for property industry in the region."
        },
        {
            id: 1,
            title: "Industry Software Leader",
            image: 'imageFeature2',
            description: "The leading IT Consultant Company that has gone through the test of time for providing excellent quality product."
        },
        {
            id: 2,
            title: "Value Innovation",
            image: 'imageFeature3',
            description: "Passion to Innovate and Delivering Exceptional Value to all our Customers all the time."
        },
        {
            id: 3,
            title: "Partnership with Our Customers",
            image: 'imageFeature4',
            description: "A shared journey to create a future for both and forging a long-term commitment that create synergies of knowledge."
        },
        {
            id: 4,
            title: "Excellent After Sales Support",
            image: 'imageFeature5',
            description: "A proven CUSTOMER CARE®™ system to provide an excellent quality in Customer Service."
        }
    ]
    
    const [onHoverHeader, setOnHoverHeader] = useState(false)
    function CarouselItem (props) {
        // setContentCarousel(props.item)
        return (
            // <Button
            //     // disabled
            //     onMouseOver={()=>setOnHoverHeader(true)}
            //     onMouseLeave={()=>setOnHoverHeader(false)}
            //     sx={{
            //         // marginTop: '32px',
            //         height: {
            //             xs: '240px',
            //             md: '600px'
            //         },
            //         width: {
            //             xs: '240px !important',
            //             md: '1200px !important'
            //         },
            //         // width: '100%',
            //         display: 'flex',
            //         justifyContent: 'center',
            //         alignItems: 'center',
            //         '&:hover': {
            //             // display: 'none'
            //             backgroundColor: 'rgba(0,0,0,0)'
            //         }
            //     }}
                
            // >
            // </Button>
            <Grid 
                container 
                spacing={0}
                sx={{
                    backgroundColor: '#35424f',
                    height: '75vh',
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <Grid 
                    item 
                    xs={7}
                    sx={{

                    }}
                >
                    <img className="home-image-header" src={
                        (props.item.id === 0) 
                            ? imageFeature1 
                            : (props.item.id === 1)
                                ? imageFeature2
                                : (props.item.id === 2)
                                    ? imageFeature3
                                    : (props.item.id === 3)
                                        ? imageFeature4
                                        : (props.item.id === 4)
                                            ? imageFeature5
                                            : null
                        } 
                    />
                </Grid>
                <Grid item xs={5} sx={{
                }}>
                    <div className="carousel-content">
                        <p className="text-style-h1-v2 text-spacing-2 color-yabes-white">{props.item.title}</p>
                        {/* <p className="text-style-h1 text-spacing-2 color-yabes-black">{props.item.title2}</p> */}
                        <p className="text-style-h6 color-yabes-white big-margin-top-bottom">{props.item.description}</p>
                        {/* <Button 
                            className="CheckButton text-style-p  text-style-bold"
                            sx={{
                                backgroundColor: 'rgba(0,0,0,0)',
                                color: '#fff',
                                borderRadius: '8px',
                                padding: '8px 16px',
                                textTransform: 'none',
                                marginTop: '8px',
                                border: '1px solid #fff',
                                '&:hover': {
                                    backgroundColor: '#000',
                                    color: '#fff',
                                }
                            }}
                        >
                            GET STARTED
                        </Button> */}
                    </div>
                </Grid>
            </Grid>
        )
    }
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <div className="homepage-container">
            <div className="home-carousel-container">
                <Box 
                    className='home-carousel' 
                    sx={{
                        // backgroundColor: '#F4F4F4',
                        border: 'none',
                        boxShadow: 'none',
                        // padding: '0 18px',
                        margin: '48px 0 180px 0',
                        width: '98.8vw',
                        // display: 'flex',
                        // alignItems: 'center'
                        // width: {
                        //     xs: '640px',
                        //     md: '1200px !important',
                        //     lg: '1200px'
                        // },
                        // height: {
                        //     xs: '500px',
                        //     // sm: '600px'
                        // }
                    }}
                >
                    <Carousel 
                        indicators={true}
                        interval={2500}
                        // navButtonsAlwaysInvisible={onHoverHeader ? false : true}
                        navButtonsAlwaysVisible
                        sx={{
                            // padding: '0 16px',
                            // marginTop: '160px',
                            width: '100vw',
                            // display: 'flex',
                            // alignItems: 'center'
                            // width: {
                            //     xs: '640px',
                            //     md: '1200px !important',
                            //     lg: '1200px'
                            // },
                            // height: {
                            //     xs: '500px',
                            //     sm: '600px'
                            // }
                        }}
                        onChange={(props)=>{
                                setCurrCar(props)
                            }
                        }
                        navButtonsProps={{
                            style: {
                                backgroundColor: 'rgba(255,255,255, 0.48)',
                                color: '000',
                                borderRadius: '4px',
                                opacity: '1',
                                margin: '0 1px 0 1px',
                                padding: '4px',
                                '&:hover': {
                                    backgroundColor: '#247BA0 !important',
                                    color: '000',
                                }
                            }
                        }}
                        NextIcon={
                            <NavigateNextIcon 
                            // onMouseOver={()=>setOnHoverHeader(true)}
                            // onMouseLeave={()=>setOnHoverHeader(false)}
                                sx={{
                                    color:'#000', 
                                    padding: '8px', 
                                    borderRadius: '8px',
                                    fontSize: '48px'
                                }}
                            />
                        }
                        PrevIcon={
                            <NavigateBeforeIcon 
                            // onMouseOver={()=>setOnHoverHeader(true)}
                            // onMouseLeave={()=>setOnHoverHeader(false)}
                                sx={{
                                    color:'#000', 
                                    padding: '8px', 
                                    borderRadius: '8px',
                                    fontSize: '48px'
                                }}
                            />
                        }
                    >
                        {
                            items.map( (item, i) => <CarouselItem key={i} item={item} /> )
                            // items.map( (item, i) => <p key={i} item={item}>{item.title1} {item.title2}</p> )
                        }
                    </Carousel>
                </Box>
            </div>
            <div className="home-welcome-container">
                <Box className="polygon-left" sx={{ display: {xs: 'none', sm: 'flex'}, alignItems: 'start' }}>
                    <img src={polygon1} width={'120px'} height={'180px'}/>
                </Box>
                <Box
                    sx={{
                        width: {
                            xs: '460px',
                            sm: '540px',
                            md: '640px',
                            lg: '720px'
                        },
                        padding: '0 16px',
                        margin: '8px 0px 42px 0',
                        // marginBottom:'400px'
                    }}
                >
                    <Typography className="text-style-h2 text-spacing-1 color-yabes-gold">
                        WELCOME
                    </Typography>
                    <Typography className="text-style-h6 color-yabes-black" sx={{ textAlign: 'left', margin: '24px 0' }}>
                        Thank you for visiting our website. We are proud to introduce our company Yabes Multipro Solusindo (YABES) which is the best local IT company devoted in providing the best software solution in the property industry. We look forward to sharing with you more news and updates.
                    </Typography>
                    <Button 
                        className="CheckButton text-style-p  text-style-bold"
                        sx={{
                            backgroundColor: 'rgba(0,0,0,0)',
                            color: '#000',
                            borderRadius: '8px',
                            padding: '8px 16px',
                            textTransform: 'none',
                            marginTop: '8px',
                            border: '1px solid #000',
                            '&:hover': {
                                backgroundColor: '#000',
                                color: '#fff',
                            }
                        }}
                    >
                        GET STARTED
                    </Button>
                </Box>
                <Box className="polygon-right" sx={{ display: {xs: 'none', sm: 'flex'}, alignItems: 'end' }}>
                    <img src={polygon2} width={'120px'} height={'180px'}/>
                </Box>
            </div>
            <div className="home-software-solution-container">
                <Box
                    sx={{
                        width: {
                            xs: '480px',
                            sm: '800px',
                            md: '1200px'
                        },
                        padding: '0 16px',
                        margin: '80px 0px',
                    }}
                >
                    <HomeSoftware />
                </Box>
            </div>
            <div className="home-feature-container">
                <Box
                    sx={{
                        width: {
                            xs: '480px',
                            sm: '800px',
                            md: '1200px'
                        },
                        padding: '0 16px',
                        margin: '80px 0px 0px 0',
                    }}
                >
                    <HomeFeature />
                    {/* <HomeSoftware /> */}
                </Box>
            </div>
            <div className="bg-spwhite">
                <div className="sp-curved-3" />
            </div>
            <div className="home-client-container">
                <Box
                  sx={{
                    width: {
                        xs: '480px',
                        md: '800px',
                        lg: '1200px'
                    },
                    padding: '0 16px',
                    margin: '64px 0px 24px 0'
                  }}
                >
                    <HomeClient matches={matches} logoClientData={logoClientDataDummy} kategoriClientData={kategoriClientDataDummy} manyClientKategoriData={manyClientKategoriDataDummy} dataClientTemp={dataClientTemp} newKategoriData={newKategoriData} filterClient={filterClient} setFilterClient={setFilterClient} filtered={filtered} setFiltered={setFiltered} alignment={alignment} setAlignment={setAlignment}/>
                </Box>
            </div>
            <div className="bg-gray">
                <div className="sp-curved-2" />
            </div>
            <div className="home-question-container">
                <Box
                    sx={{
                        // padding: '4px 120px',
                        width: {
                            xs: '480px',
                            md: '800px',
                            lg: '1200px'
                        },
                        padding: '0px 16px',
                        margin: '64px 0px 64px 0',
                        // marginBottom: '400px'
                    }}
                >
                    <QuestionSection />
                </Box>
            </div>
        </div>
    )
}

export default Homepage;