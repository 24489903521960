import React from "react";
import { Box } from "@mui/material";

import './About.css'
import './../../components/css/TextStyle.css'
import './../../components/css/ColorStyle.css'


const AboutCSF = () => {

    return(
        <div className="about-csf-container">
            <Box
                sx={{
                    width: {
                        xs: '250px',
                        // md: '640px',
                        lg: '800px'
                    },
                }}
            >
                <div className="profile-title text-style-h2 text-style-bold color-yabes-black big-margin-top-bottom text-spacing-1">
                    CRITICAL SUCCESS FACTOR
                </div>
                <div className="profile-content">
                    <div className="profile-desc">
                        <p className="text-style-h6 text-align-left color-yabes-black">
                        To ensure successful implementation of YABES Property System AdvancedProperty+ we have employed the state-of-the-art methodology called A System Implementation Methodology. This proven and widely used methodology for system Implementation begins with information gathering / analysis, conceptual design, actual design and application enhancement thru testing or QC of the application before delivering it to client.
                        </p>
                        <p className="text-style-h6 text-align-left color-yabes-black">
                        Even though the abovementioned methodology is crucial but they are just not sufficient. Others critical components are required, they are the business process knowledge know-how skill, Professional Experience/Skills and overall Property Industry knowledge of the consultants who will be implementing for YABES property project.
                        </p>
                        <p className="text-style-h6 text-align-left color-yabes-black">
                        With all the above components combined together, they will form a powerful tools to ensure the successful implementation of YABES system.
                        </p>
                    </div>
                </div>
            </Box>
        </div>
    )
}
export default AboutCSF;


