import React from "react";
import { Box, Typography, Paper } from "@mui/material";
import { Grid } from "@mui/material";
import Divider from '@mui/material/Divider';
import AOS from 'aos';
import 'aos/dist/aos.css';

import LinkIcon from '@mui/icons-material/Link';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PeopleIcon from '@mui/icons-material/People';
import EmailIcon from '@mui/icons-material/Email';
import WorkIcon from '@mui/icons-material/Work';
// import LinkIcon from '@mui/icons-material/Link';
import NoteIcon from '@mui/icons-material/Note';
import ArticleIcon from '@mui/icons-material/Article';
import GroupAddIcon from '@mui/icons-material/GroupAdd';

import CircleIcon from '@mui/icons-material/Circle';

import './css/HomeFeature.css'

const HomeFeature = () => {
    AOS.init();

    return (
        <div>
            <Box sx={{ marginBottom: '64px', display: 'flex', justifyContent: 'center' }}>
                <div data-aos="fade-up">
                    <Typography className="text-style-h2 text-spacing-1 text-style-bold color-yabes-gold" sx={{ marginBottom: '8px' }}>
                        FEATURES
                    </Typography>
                    <Divider sx={{width: '25vw', color: '#1A5974'}}><CircleIcon sx={{ fontSize: '12px', color: 'rgba(26, 89, 116, 1)' }} /></Divider>
                </div>
            </Box>
            <Grid container spacing={0}>
                <Grid item xs={12} md={4} data-aos="fade-up">
                    <Paper sx={{
                        padding: '18px',
                        boxShadow: 'none',
                        backgroundColor: '#f8fdff'
                    }}>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <LinkIcon sx={{ fontSize: '32px', marginTop: '18px', color: '#1A5974' }} />
                            </Grid>
                            <Grid item xs={9} sx={{ textAlign: 'left' }}>
                                <p className="text-style-h5 text-spacing-1 text-style-bold color-yabes-black">
                                INTEGRATED
                                </p>
                                <Divider textAlign="right" sx={{color: '#1A5974'}}><CircleIcon sx={{ fontSize: '12px', color: 'rgba(26, 89, 116, 1)' }} /></Divider>
                                <p className="text-style-h6  color-yabes-black">
                                Fully integrated software start from property development to property management system.
                                </p>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4} data-aos="fade-up">
                    <Paper sx={{
                        padding: '18px',
                        boxShadow: 'none',
                        backgroundColor: '#f8fdff'
                    }}>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <AttachMoneyIcon sx={{ fontSize: '32px', marginTop: '18px', color: '#1A5974' }} />
                            </Grid>
                            <Grid item xs={9} sx={{ textAlign: 'left' }}>
                                <p className="text-style-h5 text-spacing-1 text-style-bold color-yabes-black">
                                    EFFICIENT COST
                                </p>
                                <Divider textAlign="right" sx={{color: '#1A5974'}}><CircleIcon sx={{ fontSize: '12px', color: 'rgba(26, 89, 116, 1)' }} /></Divider>
                                <p className="text-style-h6  color-yabes-black">
                                Affordable cost for connecting data between office and project.
                                </p>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4} data-aos="fade-up">
                    <Paper sx={{
                        padding: '18px',
                        boxShadow: 'none',
                        backgroundColor: '#f8fdff'
                    }}>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <PeopleIcon sx={{ fontSize: '32px', marginTop: '18px', color: '#1A5974' }} />
                            </Grid>
                            <Grid item xs={9} sx={{ textAlign: 'left' }}>
                                <p className="text-style-h5 text-spacing-1 text-style-bold color-yabes-black">
                                UNLIMITED USER
                                </p>
                                <Divider textAlign="right" sx={{color: '#1A5974'}}><CircleIcon sx={{ fontSize: '12px', color: 'rgba(26, 89, 116, 1)' }} /></Divider>
                                <p className="text-style-h6  color-yabes-black">
                                Adding more user into the software is completely free of charge.
                                </p>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4} data-aos="fade-up">
                    <Paper sx={{
                        padding: '18px',
                        boxShadow: 'none',
                        backgroundColor: '#f8fdff'
                    }}>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <EmailIcon sx={{ fontSize: '32px', marginTop: '18px', color: '#1A5974' }} />
                            </Grid>
                            <Grid item xs={9} sx={{ textAlign: 'left' }}>
                                <p className="text-style-h5 text-spacing-1 text-style-bold color-yabes-black">
                                    SMS BLAST & EMAIL BLAST
                                </p>
                                <Divider textAlign="right" sx={{color: '#1A5974'}}><CircleIcon sx={{ fontSize: '12px', color: 'rgba(26, 89, 116, 1)' }} /></Divider>
                                <p className="text-style-h6  color-yabes-black">
                                These features provide customer relationship management and direct report to user and management.
                                </p>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4} data-aos="fade-up">
                    <Paper sx={{
                        padding: '18px',
                        boxShadow: 'none',
                        backgroundColor: '#f8fdff'
                    }}>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <WorkIcon sx={{ fontSize: '32px', marginTop: '18px', color: '#1A5974' }} />
                            </Grid>
                            <Grid item xs={9} sx={{ textAlign: 'left' }}>
                                <p className="text-style-h5 text-spacing-1 text-style-bold color-yabes-black">
                                AGENT INTERFACE
                                </p>
                                <Divider textAlign="right" sx={{color: '#1A5974'}}><CircleIcon sx={{ fontSize: '12px', color: 'rgba(26, 89, 116, 1)' }} /></Divider>
                                <p className="text-style-h6  color-yabes-black">
                                Property Agent can easily sell and book the property unit with an authorized access.
                                </p>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4} data-aos="fade-up">
                    <Paper sx={{
                        padding: '18px',
                        boxShadow: 'none',
                        backgroundColor: '#f8fdff'
                    }}>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <LinkIcon sx={{ fontSize: '32px', marginTop: '18px', color: '#1A5974' }} />
                            </Grid>
                            <Grid item xs={9} sx={{ textAlign: 'left' }}>
                                <p className="text-style-h5 text-spacing-1 text-style-bold color-yabes-black">
                                INTEGRATED WITH E-SP
                                </p>
                                <Divider textAlign="right" sx={{color: '#1A5974'}}><CircleIcon sx={{ fontSize: '12px', color: 'rgba(26, 89, 116, 1)' }} /></Divider>
                                <p className="text-style-h6  color-yabes-black">
                                Integrated with Indonesian Tax System.
                                </p>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4} data-aos="fade-up">
                    <Paper sx={{
                        padding: '18px',
                        boxShadow: 'none',
                        backgroundColor: '#f8fdff'
                    }}>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <NoteIcon sx={{ fontSize: '32px', marginTop: '18px', color: '#1A5974' }} />
                            </Grid>
                            <Grid item xs={9} sx={{ textAlign: 'left' }}>
                                <p className="text-style-h5 text-spacing-1 text-style-bold color-yabes-black">
                                    
                                AUDIT TRAIL
                                <Divider textAlign="right" sx={{color: '#1A5974'}}><CircleIcon sx={{ fontSize: '12px', color: 'rgba(26, 89, 116, 1)' }} /></Divider>
                                </p>
                                <p className="text-style-h6  color-yabes-black">
                                All user activities are recorderd by system with non removable record.
                                </p>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4} data-aos="fade-up">
                    <Paper sx={{
                        padding: '18px',
                        boxShadow: 'none',
                        backgroundColor: '#f8fdff'
                    }}>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <ArticleIcon sx={{ fontSize: '32px', marginTop: '18px', color: '#1A5974' }} />
                            </Grid>
                            <Grid item xs={9} sx={{ textAlign: 'left' }}>
                                <p className="text-style-h5 text-spacing-1 text-style-bold color-yabes-black">
                                    EASY REPORTING
                                </p>
                                <Divider textAlign="right" sx={{color: '#1A5974'}}><CircleIcon sx={{ fontSize: '12px', color: 'rgba(26, 89, 116, 1)' }} /></Divider>
                                <p className="text-style-h6  color-yabes-black">
                                Reports are easily downloaded to excel for easy report adjustment or analysis with no risk of disorganized data.
                                </p>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4} data-aos="fade-up">
                    <Paper sx={{
                        padding: '18px',
                        boxShadow: 'none',
                        backgroundColor: '#f8fdff'
                    }}>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <GroupAddIcon sx={{ fontSize: '32px', marginTop: '18px', color: '#1A5974' }} />
                            </Grid>
                            <Grid item xs={9} sx={{ textAlign: 'left' }}>
                                <p className="text-style-h5 text-spacing-1 text-style-bold color-yabes-black">
                                USER MANAGEMENT
                                </p>
                                <Divider textAlign="right" sx={{color: '#1A5974'}}><CircleIcon sx={{ fontSize: '12px', color: 'rgba(26, 89, 116, 1)' }} /></Divider>
                                <p className="text-style-h6  color-yabes-black">
                                Managing each user access and priviledge is completely easy.
                                </p>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    )
}
export default HomeFeature;