import React from "react";
import { Box } from "@mui/material";

import './About.css'
import './../../components/css/TextStyle.css'
import './../../components/css/ColorStyle.css'


const AboutVisiMisi = () => {

    return(
        <div className="about-visi-misi-container">
            <Box
                sx={{
                    width: {
                        xs: '250px',
                        // md: '640px',
                        lg: '800px'
                    },
                }}
            >
                <div className="about-visi-misi text-style-h2 text-style-bold color-yabes-black big-margin-top-bottom text-spacing-1">
                    VISION AND MISION
                </div>
                <div className="visi-title text-style-h4 text-style-bold color-yabes-gold big-margin-top-bottom text-spacing-1">
                    Company Vision
                </div>
                <div className="visi-content">
                    <div className="visi-desc text-style-h6 text-align-left color-yabes-black">
                    PT. Yabes Multipro Solusindo (YABES) seeks to be the leading IT Consulting Company in Indonesia for high quality service delivering superior value to customers, shareholders and associates.
                    </div>
                </div>
                <div className="misi-title text-style-h4 text-style-bold color-yabes-gold big-margin-top-bottom text-spacing-1">
                    Company Mision
                </div>
                <div className="misi-content">
                    <div className="misi-desc text-style-h6 text-align-left color-yabes-black">
                    To combine industry best practice and innovative technology with local greatest talent with one focus in mind, to serve and provide the highest quality of service and creating value to our client to help them achieve their organisational competitive advantage.
                    </div>
                </div>
                <div className="value-title text-style-h4 text-style-bold color-yabes-gold big-margin-top-bottom text-spacing-1">
                    YABES Core Values
                </div>
                <div className="value-content">
                    <ul className="text-style-h6 text-align-left color-yabes-black">
                        <li>Character</li>
                        <li>Discipline</li>
                        <li>Excellence</li>
                        <li>Customer Orientation</li>
                        <li>Takes Ownership</li>
                        <li>Enjoy the Journey</li>
                    </ul>
                </div>
            </Box>
        </div>
    )
}
export default AboutVisiMisi;


