import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types'

import { useScrollTrigger } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Popover from '@mui/material/Popover';
import ScrollHandler from "./ScrollHandler";
import { Grid } from "@mui/material";
import PopupState, {
  bindTrigger,
  bindPopover,
  bindHover
} from "material-ui-popup-state";
import HoverPopover from "material-ui-popup-state/HoverPopover";

import './css/Navbar.css'
import './css/TextStyle.css'
import './css/ColorStyle.css'
import yabesLogo from '../assets/logo-yabes-org.png'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CallIcon from '@mui/icons-material/Call';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const Navbar = (props) => {
  const drawerWidth = 240;
  const [view, setView] = useState('list');
  const navItems = ['Software Solution', 'News', 'Client', 'About', 'Support'];
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  
  const handleChangeLanguage = (event, nextView) => {
    setView(nextView);
  };
  const [anchorElPop, setAnchorElPop] = useState(null);

  const handleOpenPop = (event) => {
    setAnchorElPop(event.currentTarget);
  };

  const handleClosePop = () => {
    setAnchorElPop(null);
  };

  const openPop = Boolean(anchorElPop);
  const idPop = openPop ? 'simple-popover' : undefined;

  
  const styles = {
      paperContainer: {
          backgroundColor: `linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.72) 50%, rgba(0,0,0,0.24) 100%)`,
      }
  };
  
  const triggerScroll = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: props.window ? window() : undefined
  });

  // mobile
  const drawer = (
    <Box sx={{ textAlign: 'center', backgroundColor: '#F5F5F5', height: '100vh'}} className='drawer-navbar'>
      <Typography variant="h6" sx={{ my: 2 }}>
        <a href='/' >
          <img width={'80px'} src={yabesLogo} />
        </a>
      </Typography>
      <Divider />
      <List className="drawer-list" sx={{ padding: '0 18px' }}>
        <ListItem disablePadding className="drawer-list-item">
          <PopupState variant="popover" popupId="demo-popup-popover">
            {(popupState) => (
              <div>
                <Button {...bindHover(popupState)}
                  className='text-style-p text-style-bold' 
                  sx={{ color: '#000000', textTransform: 'capitalize', padding: '8px 0' }}
                >
                  SOFTWARE SOLUTION
                  <KeyboardArrowDownIcon 
                    sx={{
                      ...(popupState && popupState.isOpen === true && popupState.hovered === true && {
                        transform: 'rotate(180deg)',
                        transition: 'transform 0.2s ease'
                      }) 
                    }}
                  />
                </Button>
                <HoverPopover
                  {...bindPopover(popupState)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center"
                  }}
                >
                  <Button sx={{ display: 'block', textTransform: 'none', padding: '12px 12px 2px 12px' }} className=" text-style-h6 color-yabes-black">Property Development</Button>
                  <Button sx={{ display: 'block', textTransform: 'none', padding: '2px 12px' }} className=" text-style-h6 color-yabes-black">Property Management</Button>
                  <Button sx={{ display: 'block', textTransform: 'none', padding: '2px 12px' }} className=" text-style-h6 color-yabes-black">Construction Management</Button>
                  <Button sx={{ display: 'block', textTransform: 'none', padding: '2px 12px' }} className=" text-style-h6 color-yabes-black">Human Resource Solution</Button>
                  <Button sx={{ display: 'block', textTransform: 'none', padding: '2px 12px 12px 12px' }} className=" text-style-h6 color-yabes-black">Financial Accounting System</Button>
                </HoverPopover>
              </div>
            )}
          </PopupState>
        </ListItem>
        <ListItem disablePadding className="drawer-list-item">
          <p className='text-style-p text-style-bold'>NEWS</p>
        </ListItem>
        <ListItem disablePadding className="drawer-list-item">
          <a href="/client">
            <p className='text-style-p text-style-bold'>CLIENT</p>
          </a>
        </ListItem>
        <ListItem disablePadding className="drawer-list-item">
          <PopupState variant="popover" popupId="demo-popup-popover">
            {(popupState) => (
              <div>
                <Button {...bindHover(popupState)}
                  className='text-style-p text-style-bold' 
                  sx={{ color: '#000000', textTransform: 'capitalize', padding: '8px 0' }}
                >
                  ABOUT
                  <KeyboardArrowDownIcon 
                    sx={{
                      ...(popupState && popupState.isOpen === true && popupState.hovered === true && {
                        transform: 'rotate(180deg)',
                        transition: 'transform 0.2s ease'
                      }) 
                    }}
                  />
                </Button>
                <HoverPopover
                  {...bindPopover(popupState)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center"
                  }}
                >
                  <Button href="/about-profile" sx={{ display: 'block', textTransform: 'none', padding: '12px 12px 2px 12px' }} className=" text-style-h6 color-yabes-black">Profile</Button>
                  <Button href='/about-visi-misi' sx={{ display: 'block', textTransform: 'none', padding: '2px 12px' }} className=" text-style-h6 color-yabes-black">Vision and Mision</Button>
                  <Button href='/about-csf' sx={{ display: 'block', textTransform: 'none', padding: '2px 12px' }} className=" text-style-h6 color-yabes-black">Critical Success Factor</Button>
                  <Button href='/about-why' sx={{ display: 'block', textTransform: 'none', padding: '2px 12px' }} className=" text-style-h6 color-yabes-black">Why YABES</Button>
                  <Button href='/about-process-model' sx={{ display: 'block', textTransform: 'none', padding: '2px 12px 12px 12px' }} className=" text-style-h6 color-yabes-black">Process Model</Button>
                </HoverPopover>
              </div>
            )}
          </PopupState>
        </ListItem>
        <ListItem disablePadding className="drawer-list-item">
          <p className='text-style-p text-style-bold'>SUPPORT</p>
        </ListItem>
      </List>
      <Divider />
      <Box sx={{ my: 1, textAlign: 'left', padding: '0 18px', margin:'18px 0', display: 'block'  }}>
        <Button 
          className='text-style-p text-style-bold' 
          variant="contained" 
          sx={{ 
            backgroundColor:'rgba(0,0,0,0)',
            color: '#A57912',
            border: '1px solid #A57912',
            borderRadius: '8px',
            padding: '6px 8px',
            mr: 1,
            mb: 1,
            width: '90px',
            '&:hover': {
                backgroundColor: '#A57912',
                color: '#FFF',
            }
          }}
        >
          Login
        </Button>
        <Button 
          className='text-style-p text-style-bold' 
          variant="contained" 
          sx={{ 
            backgroundColor:'rgba(0,0,0,0)',
            color: '#A57912',
            border: '1px solid #A57912',
            borderRadius: '8px',
            padding: '6px 8px',
            mr: 1,
            mb: 1,
            width: '90px',
            '&:hover': {
              backgroundColor: '#A57912',
              color: '#FFF',
          }
          }}
        >
          Wiki
        </Button>
        <Button 
          className='text-style-p text-style-bold' 
          variant="contained" 
          sx={{ 
            backgroundColor:'#BE8C15',
            border: '1px solid #A57912',
            color: '#F4F4F4',
            textTransform: 'capitalize',
            boxShadow: 'none',
            borderRadius: '8px',
            padding: '6px 8px',
            mr: 1,
            mb: 1,
            width: '188px',
            '&:hover': {
                backgroundColor: '#000',
                color: '#FFF',
            }
          }}
        >
          CUSTOMER PORTAL
        </Button>
        <ToggleButtonGroup
          orientation="horizontal"
          value={view}
          exclusive
          onChange={handleChangeLanguage}
        >
          <ToggleButton value="list" aria-label="list" className='text-style-h6'
          sx={{
            color: '#000000',
            border: '1px solid rgba(0,0,0, 0.24)',
            padding: '8px'
          }}>
            EN
          </ToggleButton>
          <ToggleButton value="module" aria-label="module"  className='text-style-h6'
          sx={{
            color: '#000000',
            border: '1px solid rgba(0,0,0, 0.24)',
            padding: '8px'
          }}>
            ID
          </ToggleButton>
        </ToggleButtonGroup>
        <div>
        </div>
      </Box>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <div className='navbar-container'>

      <Box sx={{ display: 'flex' }}>
        {/* <CssBaseline /> */}
        <AppBar className="nav-appbar" component="nav" sx={{ backgroundColor: 'rgb(0, 0, 0, 0)', boxShadow: 'none' }}>
          <Grid container spacing={0}>
            <Grid item xs={12} sx={{ backgroundColor: 'rgb(29, 32, 32, 1)', justifyContent: 'center', display:{xs:'none', sm:'flex'} }}>
              <Box 
                sx={{ 
                  justifyContent: 'right',
                  width: {
                    xs: '640px',
                    md: '800px',
                    lg: '1200px'
                  }, 
                }}
              >
                <div className="navbar-top">
                  <Typography className='text-style-p' sx={{ marginRight: '4px' }}>Direct Marketing & Whatsapp</Typography>
                  <Button variant="contained" className='text-style-h6 color-yabes-white' sx={{ backgroundColor:'#77da55', color:'#000000', height: '36px', borderRadius: 0, boxShadow: 'none', padding: '8px' }}>
                    <WhatsAppIcon sx={{ marginRight: '2px' }}/>
                    0213106663
                  </Button>
                  <Button variant="contained" className='text-style-h6 color-yabes-white' sx={{ backgroundColor:'#77da55', color:'#000000', height: '36px', borderRadius: 0, boxShadow: 'none', padding: '8px' }}>
                    <CallIcon sx={{ marginRight: '2px' }}/>
                    6287776868717
                  </Button>
                </div>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Toolbar sx={{ 
                  // background: triggerScroll ? '#F4F4F4' :'linear-gradient(180deg, rgba(29,32,32,1) 0%, rgba(29,32,32,0.72) 50%, rgba(29,32,32,0) 100%)',
                  backgroundColor: '#FFFFFF',
                  border: 'none',
                  // boxShadow: '0px 4px 40px rgba(0, 0, 0, .08)',
                  boxShadow: triggerScroll ? '0px 4px 12px rgba(0, 0, 0, .08)' : 'none',
                  display: 'flex',
                  justifyContent: 'center',
                  padding: {
                    xs: '0px',
                    md: '0 64px'
                  },
                }}
                className="navbar-down"
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'left',
                    alignItems: 'center',
                    width: {
                      xs: '640px',
                      md: '800px',
                      lg: '1200px'
                    },
                    padding: {
                      xs: '0 16px',
                      sm: '0'
                    }
                  }}
                >
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{ mr: 2, display: { md: 'none' }, color: '#000000' }}
                  >
                    <MenuIcon />
                  </IconButton>
                  
                  <Box variant="h6" sx={{ my: 2, color: '#000000', display: { xs: 'block', md: 'block' } }}>
                    <a href='/' >
                      <img width={'80px'} src={yabesLogo} />
                    </a>
                  </Box>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{ flexGrow: 1, color: '#F5F5F5', display: { xs: 'none', md: 'flex' }, justifyContent: 'center' }}
                  >
                    <PopupState variant="popover" popupId="demo-popup-popover">
                      {(popupState) => (
                        <div>
                          <Button {...bindHover(popupState)}
                            className='text-style-p text-style-bold' 
                            sx={{ color: '#000000', textTransform: 'capitalize' }}
                          >
                            SOFTWARE SOLUTION
                            <KeyboardArrowDownIcon 
                              sx={{
                                ...(popupState && popupState.isOpen === true && popupState.hovered === true && {
                                  transform: 'rotate(180deg)',
                                  transition: 'transform 0.2s ease'
                                }) 
                              }}
                            />
                          </Button>
                          <HoverPopover
                            {...bindPopover(popupState)}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "center"
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "center"
                            }}
                          >
                            <Button sx={{ display: 'block', textTransform: 'none', padding: '12px 12px 2px 12px' }} className=" text-style-h6 color-yabes-black">Property Development</Button>
                            <Button sx={{ display: 'block', textTransform: 'none', padding: '2px 12px' }} className=" text-style-h6 color-yabes-black">Property Management</Button>
                            <Button sx={{ display: 'block', textTransform: 'none', padding: '2px 12px' }} className=" text-style-h6 color-yabes-black">Construction Management</Button>
                            <Button sx={{ display: 'block', textTransform: 'none', padding: '2px 12px' }} className=" text-style-h6 color-yabes-black">Human Resource Solution</Button>
                            <Button sx={{ display: 'block', textTransform: 'none', padding: '2px 12px 12px 12px' }} className=" text-style-h6 color-yabes-black">Financial Accounting System</Button>
                          </HoverPopover>
                        </div>
                      )}
                    </PopupState>
                    <Button href="/blog" className='text-style-p text-style-bold ' sx={{ color: '#000000', textTransform: 'capitalize' }}>
                      NEWS
                    </Button>
                    <Button href="/client" className='text-style-p text-style-bold ' sx={{ color: '#000000', textTransform: 'capitalize' }}>
                      CLIENT
                    </Button>
                    <PopupState variant="popover" popupId="demo-popup-popover">
                      {(popupState) => (
                        <div>
                          <Button {...bindHover(popupState)}
                            className='text-style-p text-style-bold' 
                            sx={{ color: '#000000', textTransform: 'capitalize' }}
                          >
                            ABOUT
                            <KeyboardArrowDownIcon 
                              sx={{
                                ...(popupState && popupState.isOpen === true && popupState.hovered === true && {
                                  transform: 'rotate(180deg)',
                                  transition: 'transform 0.2s ease'
                                }) 
                              }}
                            />
                          </Button>
                          <HoverPopover
                            {...bindPopover(popupState)}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "center"
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "center"
                            }}
                          >
                            <Button href="/about-profile" sx={{ display: 'block', textTransform: 'none', padding: '12px 12px 2px 12px' }} className=" text-style-h6 color-yabes-black">Profile</Button>
                            <Button href='/about-visi-misi' sx={{ display: 'block', textTransform: 'none', padding: '2px 12px' }} className=" text-style-h6 color-yabes-black">Vision and Mision</Button>
                            <Button href='/about-csf' sx={{ display: 'block', textTransform: 'none', padding: '2px 12px' }} className=" text-style-h6 color-yabes-black">Critical Success Factor</Button>
                            <Button href='/about-why' sx={{ display: 'block', textTransform: 'none', padding: '2px 12px' }} className=" text-style-h6 color-yabes-black">Why YABES</Button>
                            <Button href='/about-process-model' sx={{ display: 'block', textTransform: 'none', padding: '2px 12px 12px 12px' }} className=" text-style-h6 color-yabes-black">Process Model</Button>
                          </HoverPopover>
                        </div>
                      )}
                    </PopupState>
                    <Button className='text-style-p text-style-bold ' sx={{ color: '#000000', textTransform: 'capitalize' }}>
                      SUPPORT
                    </Button>
                  </Typography>
                  <Box sx={{ my: 1, display: { xs: 'none', md: 'flex' }, alignItems: 'center', justifyContent: 'center' }}>
                    <Button 
                      className='text-style-p text-style-bold' 
                      variant="contained" 
                      sx={{ 
                        backgroundColor:'rgba(0,0,0,0)',
                        color: '#A57912',
                        border: '1px solid #A57912',
                        borderRadius: '8px',
                        padding: '6px 8px',
                        mr: 1,
                        '&:hover': {
                            backgroundColor: '#A57912',
                            color: '#FFF',
                        }
                      }}
                    >
                      Login
                    </Button>
                    <Button 
                      className='text-style-p text-style-bold' 
                      variant="contained" 
                      sx={{ 
                        backgroundColor:'rgba(0,0,0,0)',
                        color: '#A57912',
                        border: '1px solid #A57912',
                        borderRadius: '8px',
                        padding: '6px 8px',
                        mr: 1,
                        '&:hover': {
                          backgroundColor: '#A57912',
                          color: '#FFF',
                      }
                      }}
                    >
                      Wiki
                    </Button>
                    <Button 
                      className='text-style-p text-style-bold' 
                      variant="contained" 
                      sx={{ 
                        backgroundColor:'#BE8C15',
                        border: '1px solid #A57912',
                        color: '#F4F4F4',
                        textTransform: 'capitalize',
                        boxShadow: 'none',
                        borderRadius: '8px',
                        padding: '6px 8px',
                        mr: 1,
                        '&:hover': {
                            backgroundColor: '#000',
                            color: '#FFF',
                        }
                      }}
                    >
                      CUSTOMER PORTAL
                    </Button>
                    <ToggleButtonGroup
                      orientation="vertical"
                      value={view}
                      exclusive
                      onChange={handleChangeLanguage}
                    >
                      <ToggleButton value="list" aria-label="list" className='text-style-h6'
                      sx={{
                        color: '#000000',
                        border: 'none',
                        padding: '0'
                      }}>
                        EN
                      </ToggleButton>
                      <ToggleButton value="module" aria-label="module"  className='text-style-h6'
                      sx={{
                        color: '#000000',
                        border: 'none',
                        padding: '0'
                      }}>
                        ID
                      </ToggleButton>
                    </ToggleButtonGroup>
                    <div>
                    </div>
                  </Box>
                </Box>
              </Toolbar>
              {/* <ScrollHandler>
              </ScrollHandler> */}
            </Grid>
          </Grid>
        </AppBar>
        <nav>
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', md: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            {drawer}
          </Drawer>
        </nav>
        {/* <Toolbar /> */}
      </Box>
      <Popover
        id={idPop}
        open={openPop}
        anchorEl={anchorElPop}
        onClose={handleClosePop}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Button sx={{ display: 'block', textTransform: 'none', padding: '8px 12px' }} className=" text-style-h6 color-yabes-black">Property Development</Button>
        <Button sx={{ display: 'block', textTransform: 'none', padding: '8px 12px' }} className=" text-style-h6 color-yabes-black">Property Management</Button>
        <Button sx={{ display: 'block', textTransform: 'none', padding: '8px 12px' }} className=" text-style-h6 color-yabes-black">Construction Management</Button>
        <Button sx={{ display: 'block', textTransform: 'none', padding: '8px 12px' }} className=" text-style-h6 color-yabes-black">Human Resource Solution</Button>
        <Button sx={{ display: 'block', textTransform: 'none', padding: '8px 12px' }} className=" text-style-h6 color-yabes-black">Financial Accounting System</Button>
      </Popover>
    </div>
  );
    // return(
    //     <div>
    //         Navbar Testing
    //     </div>
    // )
}

Navbar.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default Navbar;
