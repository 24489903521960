import React from "react";
import { Grid } from "@mui/material";
import { Card } from "@mui/material";
import { Box } from "@mui/material";

const DetailClient = (props) => {
    return (
        <Box 
        sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: {
                xs: '280px',
                md: '1200px'
            },
            height: {
                xs: '480px',
                md: '480px'
            },
            bgcolor: 'background.paper',
            border: '1px solid #000',
            borderRadius: '4px',
            boxShadow: 24,
            // p: 4,
            display: ' flex',
            justifyContent: 'center',
            alignItems: 'center'                
            }}
        >
            <Grid 
                container 
                spacing={0}
                sx={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '4px',
                    overflowY: 'auto'
                }}
            >
                <Grid 
                    item 
                    xs={12}
                    md={3}
                    sx={{
                        // height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    
                    <Card sx={{ border: 'none', boxShadow: 'none', display: 'flex', justifyContent: 'center' }}>
                        <img
                        // src={`${window.location.origin}/gambar_client/detail_client/Grand Dharmahusada Lagoon.png`}
                        src={`${window.location.origin}/gambar_client/Yabes/pp0.png`}
                        alt="Client Logo"
                        width= '90%'
                        className='client-logo img-fluid'
                        />
                    </Card>
                </Grid>
                <Grid item xs={12} md={9}>
                    <Grid container spacing={1}>
                        <Grid item xs={6} md={2}>
                            <Card sx={{ height: '104px', width: '136px', display: 'flex', justifyContent: 'center' }}>
                                <img
                                    src={`${window.location.origin}/gambar_client/detail_client/client3/Grand Dharmahusada Lagoon.png`}
                                    width={'100%'}
                                    // height={'100%'}
                                    alt="Project Client Logo"
                                        className='detail-client-logo img-fluid'
                                    />
                            </Card>
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <Card sx={{ height: '104px', width: '136px', display: 'flex', justifyContent: 'center' }}>
                                <img
                                    src={`${window.location.origin}/gambar_client/detail_client/client3/Grand Kamala Lagoon.png`}
                                    width={'100%'}
                                    // height={'100%'}
                                    alt="Project Client Logo"
                                    className='detail-client-logo img-fluid'
                                />
                            </Card>
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <Card sx={{ height: '104px', width: '136px', display: 'flex', justifyContent: 'center' }}>
                                <img
                                    src={`${window.location.origin}/gambar_client/detail_client/client3/Grand Shamaya Surabaya.png`}
                                    width={'100%'}
                                    // height={'100%'}
                                    alt="Project Client Logo"
                                    className='detail-client-logo img-fluid'
                                />
                            </Card>
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <Card sx={{ height: '104px', width: '136px', display: 'flex', justifyContent: 'center' }}>
                                <img
                                    src={`${window.location.origin}/gambar_client/detail_client/client3/Grand Sungkono Lagoon.jpg`}
                                    width={'100%'}
                                    // height={'100%'}
                                    alt="Project Client Logo"
                                    className='detail-client-logo img-fluid'
                                />
                            </Card>
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <Card sx={{ height: '104px', width: '136px', display: 'flex', justifyContent: 'center' }}>
                                <img
                                    src={`${window.location.origin}/gambar_client/detail_client/client3/Lagoon Avenue Bekasi.png`}
                                    width={'100%'}
                                    // height={'100%'}
                                    alt="Project Client Logo"
                                    className='detail-client-logo img-fluid'
                                />
                            </Card>
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <Card sx={{ height: '104px', width: '136px', display: 'flex', justifyContent: 'center' }}>
                                <img
                                    src={`${window.location.origin}/gambar_client/detail_client/client3/PP PROPERTI Tbk.png`}
                                    width={'100%'}
                                    // height={'100%'}
                                    alt="Project Client Logo"
                                    className='detail-client-logo img-fluid'
                                />
                            </Card>
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <Card sx={{ height: '104px', width: '136px', display: 'flex', justifyContent: 'center' }}>
                                <img
                                    src={`${window.location.origin}/gambar_client/detail_client/client3/Lagoon Avenue Bekasi.png`}
                                    width={'100%'}
                                    // height={'100%'}
                                    alt="Project Client Logo"
                                    className='detail-client-logo img-fluid'
                                />
                            </Card>
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <Card sx={{ height: '104px', width: '136px', display: 'flex', justifyContent: 'center' }}>
                                <img
                                    src={`${window.location.origin}/gambar_client/detail_client/client3/PP PROPERTI Tbk.png`}
                                    width={'100%'}
                                    // height={'100%'}
                                    alt="Project Client Logo"
                                    className='detail-client-logo img-fluid'
                                />
                            </Card>
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <Card sx={{ height: '104px', width: '136px', display: 'flex', justifyContent: 'center' }}>
                                <img
                                    src={`${window.location.origin}/gambar_client/detail_client/client3/Lagoon Avenue Bekasi.png`}
                                    width={'100%'}
                                    // height={'100%'}
                                    alt="Project Client Logo"
                                    className='detail-client-logo img-fluid'
                                />
                            </Card>
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <Card sx={{ height: '104px', width: '136px', display: 'flex', justifyContent: 'center' }}>
                                <img
                                    src={`${window.location.origin}/gambar_client/detail_client/client3/PP PROPERTI Tbk.png`}
                                    width={'100%'}
                                    // height={'100%'}
                                    alt="Project Client Logo"
                                    className='detail-client-logo img-fluid'
                                />
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}
export default DetailClient;


