import React, { useEffect, useState } from "react";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
// import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import { Box } from "@mui/material";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import './Client.css'
import './../../components/css/ColorStyle.css'
import './../../components/css/TextStyle.css'
// import { variables } from "../../Variables";
import DetailClient from "../../components/DetailClient";

const Client = () => {
    const [listKategoriClient, setlistKategoriClient] = useState([
        {id: 1, kategori: 'Landed Housing'},
        {id: 2, kategori: 'Apartment'},
        {id: 3, kategori: 'Mall'},
        {id: 4, kategori: 'Office Tower'},
        {id: 5, kategori: 'Industrial Estate'},
        {id: 6, kategori: 'Mixed Used Development'},
    ])
    const [filterClient, setFilterClient] = useState(0)
    const matches = useMediaQuery("(min-width:600px)");
    const [filtered, setFiltered] = useState(false)
    const [alignment, setAlignment] = useState('web');
    const [dataClient, setDataClient] = useState([
     /*   {
            'id' : 1,
            'kategori' : 3,
            'gambar' : '1597406448822.png'
        }, */
        {
            'id' : 2,
            'kategori' : 3,
            'gambar' : '220px-TangCity_Mall_Logo.png'
        },
        {
            'id' : 3,
            'kategori' : 5,
            'gambar' : 'download.png'
        },
        {
            'id' : 4,
            'kategori' : 4,
            'gambar' : 'og-facebook.png'
        },
        {
            'id' : 5,
            'kategori' : 2,
            'gambar' : 'download_(1).png'
        },
        {
            'id' : 6,
            'kategori' : 4,
            'gambar' : 'Sudirman_Plaza.png'
        },
        {
            'id' : 7,
            'kategori' : 3,
            'gambar' : 'Screenshot_2023-03-16_133640.png'
        },
        {
            'id' : 8,
            'kategori' : 1,
            'gambar' : 'ancora.jpeg'
        },
        {
            'id' : 9,
            'kategori' : 3,
            'gambar' : 'cilegon_center_mall.png'
        },
        {
            'id' : 10,
            'kategori' : 6,
            'gambar' : 'bali-national-golf.png'
        },
        {
            'id' : 11,
            'kategori' : 1,
            'gambar' : 'arggr.png'
        },
        {
            'id' : 12,
            'kategori' : 1,
            'gambar' : 'qweqwe.png'
        },
        {
            'id' : 13,
            'kategori' : 1,
            'gambar' : 'Screenshot_2023-03-16_134027.png'
        },
        {
            'id' : 14,
            'kategori' : 1,
            'gambar' : 'mustika_i_village.png'
        },
        {
            'id' : 15,
            'kategori' : 2,
            'gambar' : 'GDDP_BINTARO.png'
        },
        {
            'id' : 16,
            'kategori' : 2,
            'gambar' : 'HIPILAND.png'
        },
        {
            'id' : 17,
            'kategori' : 3,
            'gambar' : 'winland.png'
        },
        {
            'id' : 18,
            'kategori' : 3,
            'gambar' : 'assa-land-surabaya.png'
        },
        {
            'id' : 19,
            'kategori' : 5,
            'gambar' : 'Logo-Gardens-PNG-edit1.png'
        },
        {
            'id' : 20,
            'kategori' : 5,
            'gambar' : 'Screenshot_2023-03-16_133330.png'
        },
        {
            'id' : 21,
            'kategori' : 2,
            'gambar' : 'amega.png'
        },
        {
            'id' : 22,
            'kategori' : 6,
            'gambar' : 'Logo-Central-Group-1024x730.png'
        },
        {
            'id' : 23,
            'kategori' : 2,
            'gambar' : 'File_Master_WG_Nov21_(1).png'
        },
        {
            'id' : 24,
            'kategori' : 1,
            'gambar' : 'KAREUMBI-GROUP.png'
        },
        {
            'id' : 25,
            'kategori' : 2,
            'gambar' : 'gibland.png'
        },
        {
            'id' : 26,
            'kategori' : 2,
            'gambar' : 'kalla-group.png'
        },
        {
            'id' : 27,
            'kategori' : 2,
            'gambar' : 'sdsd.png'
        },
        {
            'id' : 28,
            'kategori' : 1,
            'gambar' : 'qweq.png'
        },
        {
            'id' : 29,
            'kategori' : 5,
            'gambar' : 'Akses_Kebomas.png'
        },
        {
            'id' : 30,
            'kategori' : 4,
            'gambar' : 'client19.png'
        },
        {
            'id' : 31,
            'kategori' : 2,
            'gambar' : 'the_linden_logo.png'
        },
        {
            'id' : 32,
            'kategori' : 1,
            'gambar' : 'grand_mekarsari.png'
        },
        {
            'id' : 33,
            'kategori' : 1,
            'gambar' : 'client14.png'
        },
        {
            'id' : 34,
            'kategori' : 1,
            'gambar' : 'client13.png'
        },
        {
            'id' : 35,
            'kategori' : 2,
            'gambar' : 'client2.png'
        },
        {
            'id' : 36,
            'kategori' : 2,
            'gambar' : 'client1.png'
        },
        {
            'id' : 37,
            'kategori' : 1,
            'gambar' : 'hakiki.png'
        },
        {
            'id' : 38,
            'kategori' : 6,
            'gambar' : 'samator-group.png'
        },
        {
            'id' : 39,
            'kategori' : 1,
            'gambar' : 'client17.png'
        },
        {
            'id' : 40,
            'kategori' : 1,
            'gambar' : 'client8.png'
        },
        {
            'id' : 41,
            'kategori' : 4,
            'gambar' : 'client25.png'
        },
        {
            'id' : 42,
            'kategori' : 4,
            'gambar' : 'serpong_gareden.png'
        },
        {
            'id' : 43,
            'kategori' : 1,
            'gambar' : 'client23.png'
        },
        {
            'id' : 44,
            'kategori' : 1,
            'gambar' : 'taman_jarisari_permai.png'
        },
        {
            'id' : 45,
            'kategori' : 1,
            'gambar' : 'Heavenland_Park.png'
        },
        {
            'id' : 46,
            'kategori' : 1,
            'gambar' : 'Green_City_Residence.png'
        },
        {
            'id' : 47,
            'kategori' : 5,
            'gambar' : 'marunda.png'
        },
        {
            'id' : 48,
            'kategori' : 1,
            'gambar' : 'bukit_baruga.png'
        },
        {
            'id' : 49,
            'kategori' : 6,
            'gambar' : 'client26.png'
        },
        {
            'id' : 50,
            'kategori' : 4,
            'gambar' : 'asana-residence.png'
        },
        {
            'id' : 51,
            'kategori' : 1,
            'gambar' : 'client22.png'
        },
        {
            'id' : 52,
            'kategori' : 2,
            'gambar' : 'client21.png'
        },
        {
            'id' : 53,
            'kategori' : 1,
            'gambar' : 'client10.png'
        },
        {
            'id' : 54,
            'kategori' : 4,
            'gambar' : 'client4.png'
        },
        {
            'id' : 55,
            'kategori' : 1,
            'gambar' : 'client12.png'
        },
        
    ])

    // client
    const [logoClientData, setLogoClientData] = useState([])
    const [dataClientTemp, setDataClientTemp] = useState([])    
    // const [logoClientDataDummy, setLogoClientDataDummy] = useState([
    //     {
    //         "id": 1,
    //         "no_urut": 100,
    //         "kategori_id": 1,
    //         "link": "https://www.citraswarna.com/product/category/4/Riverside.html",
    //         "gambar": "client12.png",
    //         "type": "Vertikal",
    //         "new_flag": 0,
    //         "created_at": "2021-02-22T07:30:53",
    //         "updated_at": "2021-04-08T11:46:50",
    //         "deleted_at": null
    //     },
    //     {
    //         "id": 2,
    //         "no_urut": 100,
    //         "kategori_id": 1,
    //         "link": "https://www.cileungsigraharaya.com/",
    //         "gambar": "client8.png",
    //         "type": "Vertikal",
    //         "new_flag": 0,
    //         "created_at": "2021-02-22T07:31:17",
    //         "updated_at": "2021-04-08T11:47:08",
    //         "deleted_at": null
    //     },
    //     {
    //         "id": 3,
    //         "no_urut": 100,
    //         "kategori_id": 1,
    //         "link": "https://ateraland.com/",
    //         "gambar": "client17.png",
    //         "type": "Vertikal",
    //         "new_flag": 0,
    //         "created_at": "2021-02-22T07:31:58",
    //         "updated_at": "2021-04-08T11:47:08",
    //         "deleted_at": null
    //     },
    //     {
    //         "id": 4,
    //         "no_urut": 15,
    //         "kategori_id": 1,
    //         "link": "https://www.citraswarna.com/",
    //         "gambar": "client9.png",
    //         "type": "Vertikal",
    //         "new_flag": 0,
    //         "created_at": "2021-02-22T07:32:12",
    //         "updated_at": "2023-03-16T13:39:34",
    //         "deleted_at": "2023-03-16T13:39:34"
    //     },
    //     {
    //         "id": 5,
    //         "no_urut": 10,
    //         "kategori_id": 0,
    //         "link": "https://www.samator.com/en/our-group/property-industry1",
    //         "gambar": "samator-group.png",
    //         "type": "Vertikal",
    //         "new_flag": 0,
    //         "created_at": "2021-02-22T07:32:31",
    //         "updated_at": "2021-09-09T10:40:32",
    //         "deleted_at": null
    //     },
    //     {
    //         "id": 6,
    //         "no_urut": 18,
    //         "kategori_id": 0,
    //         "link": "https://serponggarden.com/",
    //         "gambar": "hakiki.png",
    //         "type": "Vertikal",
    //         "new_flag": 0,
    //         "created_at": "2021-02-22T07:32:52",
    //         "updated_at": "2021-09-09T10:40:56",
    //         "deleted_at": null
    //     },
    //     {
    //         "id": 7,
    //         "no_urut": 100,
    //         "kategori_id": 2,
    //         "link": "http://rosevillesohosuite.com",
    //         "gambar": "client1.png",
    //         "type": "Vertikal",
    //         "new_flag": 0,
    //         "created_at": "2021-02-22T07:40:45",
    //         "updated_at": "2021-04-08T11:48:55",
    //         "deleted_at": null
    //     },
    //     {
    //         "id": 8,
    //         "no_urut": 11,
    //         "kategori_id": 2,
    //         "link": "http://rosevillesohosuite.com",
    //         "gambar": "client2.png",
    //         "type": "Vertikal",
    //         "new_flag": 0,
    //         "created_at": "2021-02-22T07:41:20",
    //         "updated_at": "2021-09-09T10:40:35",
    //         "deleted_at": null
    //     },
    //     {
    //         "id": 9,
    //         "no_urut": 100,
    //         "kategori_id": 2,
    //         "link": "https://vila-rizki-ilhami-vri-plus.business.site/",
    //         "gambar": "client13.png",
    //         "type": "Vertikal",
    //         "new_flag": 0,
    //         "created_at": "2021-02-22T07:41:57",
    //         "updated_at": "2021-04-08T11:47:08",
    //         "deleted_at": null
    //     },
    //     {
    //         "id": 10,
    //         "no_urut": 13,
    //         "kategori_id": 2,
    //         "link": "https://vila-rizki-ilhami-vri-plus.business.site/",
    //         "gambar": "client14.png",
    //         "type": "Vertikal",
    //         "new_flag": 0,
    //         "created_at": "2021-02-22T07:42:08",
    //         "updated_at": "2021-09-09T10:40:40",
    //         "deleted_at": null
    //     },
    //     {
    //         "id": 11,
    //         "no_urut": 100,
    //         "kategori_id": 0,
    //         "link": "http://www.grandmekarsariresidence.com/tentangkami/deskripsi",
    //         "gambar": "grand_mekarsari.png",
    //         "type": "Vertikal",
    //         "new_flag": 0,
    //         "created_at": "2021-02-22T07:43:02",
    //         "updated_at": "2021-04-08T11:47:08",
    //         "deleted_at": null
    //     },
    //     {
    //         "id": 12,
    //         "no_urut": 100,
    //         "kategori_id": 3,
    //         "link": "http://tamanjatisaripermai.com/",
    //         "gambar": "client7.png",
    //         "type": "Vertikal",
    //         "new_flag": 0,
    //         "created_at": "2021-02-22T07:43:16",
    //         "updated_at": "2021-04-08T11:47:08",
    //         "deleted_at": "2021-03-08T11:09:38"
    //     },
    //     {
    //         "id": 13,
    //         "no_urut": 100,
    //         "kategori_id": 3,
    //         "link": "https://thelindenapartments.com/",
    //         "gambar": "the_linden_logo.png",
    //         "type": "Horizontal",
    //         "new_flag": 0,
    //         "created_at": "2021-02-22T07:43:28",
    //         "updated_at": "2021-04-08T11:47:08",
    //         "deleted_at": null
    //     },
    //     {
    //         "id": 14,
    //         "no_urut": 100,
    //         "kategori_id": 3,
    //         "link": "http://www.grandmekarsariresidence.com/",
    //         "gambar": "client5.png",
    //         "type": "Vertikal",
    //         "new_flag": 0,
    //         "created_at": "2021-02-22T07:43:43",
    //         "updated_at": "2021-04-08T11:47:08",
    //         "deleted_at": "2021-03-08T10:47:13"
    //     },
    //     {
    //         "id": 15,
    //         "no_urut": 9,
    //         "kategori_id": 4,
    //         "link": "http://permatacimanggis.co.id/",
    //         "gambar": "client19.png",
    //         "type": "Horizontal",
    //         "new_flag": 0,
    //         "created_at": "2021-02-22T07:44:21",
    //         "updated_at": "2021-09-09T10:40:28",
    //         "deleted_at": null
    //     },
    //     {
    //         "id": 16,
    //         "no_urut": 100,
    //         "kategori_id": 4,
    //         "link": "https://mataharilandproperty.wordpress.com/",
    //         "gambar": "client25.png",
    //         "type": "Horizontal",
    //         "new_flag": 0,
    //         "created_at": "2021-02-22T07:44:33",
    //         "updated_at": "2021-04-08T11:47:08",
    //         "deleted_at": null
    //     },
    //     {
    //         "id": 17,
    //         "no_urut": 100,
    //         "kategori_id": 0,
    //         "link": "https://serponggarden.com/",
    //         "gambar": "serpong_gareden.png",
    //         "type": "Vertikal",
    //         "new_flag": 0,
    //         "created_at": "2021-02-22T07:44:58",
    //         "updated_at": "2021-09-09T10:41:12",
    //         "deleted_at": null
    //     },
    //     {
    //         "id": 18,
    //         "no_urut": 100,
    //         "kategori_id": 4,
    //         "link": "https://www.ciruasland.co.id/",
    //         "gambar": "client23.png",
    //         "type": "Vertikal",
    //         "new_flag": 0,
    //         "created_at": "2021-02-22T07:45:12",
    //         "updated_at": "2021-04-08T11:47:08",
    //         "deleted_at": null
    //     },
    //     {
    //         "id": 19,
    //         "no_urut": 100,
    //         "kategori_id": 5,
    //         "link": "https://menara-kuningan.business.site/",
    //         "gambar": "client4.png",
    //         "type": "Horizontal",
    //         "new_flag": 0,
    //         "created_at": "2021-02-22T07:45:50",
    //         "updated_at": "2021-04-08T11:47:08",
    //         "deleted_at": null
    //     },
    //     {
    //         "id": 20,
    //         "no_urut": 100,
    //         "kategori_id": 5,
    //         "link": "https://www.citraswarna.com/product/category/2/Kartika-Residence.html",
    //         "gambar": "client10.png",
    //         "type": "Horizontal",
    //         "new_flag": 0,
    //         "created_at": "2021-02-22T07:46:03",
    //         "updated_at": "2021-04-08T11:47:08",
    //         "deleted_at": null
    //     },
    //     {
    //         "id": 21,
    //         "no_urut": 100,
    //         "kategori_id": 5,
    //         "link": "https://www.citraswarna.com/product/category/3/Citraswarna-Grande.html",
    //         "gambar": "client11.png",
    //         "type": "Horizontal",
    //         "new_flag": 0,
    //         "created_at": "2021-02-22T07:46:15",
    //         "updated_at": "2023-03-09T23:37:49",
    //         "deleted_at": "2023-03-09T23:37:49"
    //     },
    //     {
    //         "id": 22,
    //         "no_urut": 16,
    //         "kategori_id": 5,
    //         "link": "https://www.megapolitan-group.com/",
    //         "gambar": "client20.jpg",
    //         "type": "Vertikal",
    //         "new_flag": 0,
    //         "created_at": "2021-02-22T07:46:27",
    //         "updated_at": "2022-08-29T09:59:02",
    //         "deleted_at": "2022-08-29T09:59:02"
    //     },
    //     {
    //         "id": 23,
    //         "no_urut": 6,
    //         "kategori_id": 6,
    //         "link": "https://themajbekasi.com/blog/Groundbreaking Ceremony The MAJ Residences Bekasi",
    //         "gambar": "client21.png",
    //         "type": "Vertikal",
    //         "new_flag": 0,
    //         "created_at": "2021-02-22T07:47:32",
    //         "updated_at": "2021-09-09T10:40:20",
    //         "deleted_at": null
    //     },
    //     {
    //         "id": 24,
    //         "no_urut": 100,
    //         "kategori_id": 6,
    //         "link": "https://www.ciruasland.co.id/",
    //         "gambar": "client22.jpg",
    //         "type": "Vertikal",
    //         "new_flag": 0,
    //         "created_at": "2021-02-22T07:47:49",
    //         "updated_at": "2021-04-26T14:21:47",
    //         "deleted_at": null
    //     },
    //     {
    //         "id": 25,
    //         "no_urut": 7,
    //         "kategori_id": 0,
    //         "link": "https://asanaresidence.com/",
    //         "gambar": "asana-residence.png",
    //         "type": "Vertikal",
    //         "new_flag": 0,
    //         "created_at": "2021-02-22T07:48:09",
    //         "updated_at": "2021-09-09T10:40:23",
    //         "deleted_at": null
    //     },
    //     {
    //         "id": 26,
    //         "no_urut": 100,
    //         "kategori_id": 6,
    //         "link": "https://mataharilandproperty.wordpress.com/",
    //         "gambar": "client26.jpg",
    //         "type": "Vertikal",
    //         "new_flag": 0,
    //         "created_at": "2021-02-22T07:49:32",
    //         "updated_at": "2021-04-08T11:47:08",
    //         "deleted_at": null
    //     },
    //     {
    //         "id": 28,
    //         "no_urut": 100,
    //         "kategori_id": 0,
    //         "link": "https://bukitbaruga.co.id/",
    //         "gambar": "bukit_baruga.png",
    //         "type": "Vertikal",
    //         "new_flag": 0,
    //         "created_at": "2021-02-23T09:19:44",
    //         "updated_at": "2021-04-08T11:47:08",
    //         "deleted_at": null
    //     },
    //     {
    //         "id": 29,
    //         "no_urut": 8,
    //         "kategori_id": 5,
    //         "link": "https://marundacenter.co.id/id/index",
    //         "gambar": "marunda.png",
    //         "type": "Vertikal",
    //         "new_flag": 0,
    //         "created_at": "2021-02-23T09:21:03",
    //         "updated_at": "2021-09-09T10:40:25",
    //         "deleted_at": null
    //     },
    //     {
    //         "id": 30,
    //         "no_urut": 100,
    //         "kategori_id": 0,
    //         "link": "https://www.facebook.com/thegreencityresidence/",
    //         "gambar": "Green_City_Residence.jpg",
    //         "type": "Horizontal",
    //         "new_flag": 0,
    //         "created_at": "2021-02-25T06:37:03",
    //         "updated_at": "2021-04-08T11:47:08",
    //         "deleted_at": null
    //     },
    //     {
    //         "id": 31,
    //         "no_urut": 100,
    //         "kategori_id": 0,
    //         "link": "https://www.grahamukti.com/goldengate/",
    //         "gambar": "Heavenland_Park.png",
    //         "type": "Vertikal",
    //         "new_flag": 0,
    //         "created_at": "2021-02-25T06:39:23",
    //         "updated_at": "2021-04-08T11:47:08",
    //         "deleted_at": null
    //     },
    //     {
    //         "id": 32,
    //         "no_urut": 100,
    //         "kategori_id": 0,
    //         "link": "http://tamanjatisaripermai.com/",
    //         "gambar": "taman_jarisari_permai.png",
    //         "type": "Horizontal",
    //         "new_flag": 0,
    //         "created_at": "2021-02-25T06:48:06",
    //         "updated_at": "2021-04-08T11:47:08",
    //         "deleted_at": null
    //     },
    //     {
    //         "id": 33,
    //         "no_urut": 100,
    //         "kategori_id": 0,
    //         "link": "https://akseskebomas.co.id/",
    //         "gambar": "Akses_Kebomas.png",
    //         "type": "Vertikal",
    //         "new_flag": 0,
    //         "created_at": "2021-02-25T07:10:10",
    //         "updated_at": "2021-04-08T11:47:08",
    //         "deleted_at": null
    //     },
    //     {
    //         "id": 34,
    //         "no_urut": 100,
    //         "kategori_id": 0,
    //         "link": "https://kokohcity.com/",
    //         "gambar": "qweq.JPG",
    //         "type": "Vertikal",
    //         "new_flag": 0,
    //         "created_at": "2021-02-25T07:16:41",
    //         "updated_at": "2021-04-08T11:47:08",
    //         "deleted_at": null
    //     },
    //     {
    //         "id": 35,
    //         "no_urut": 100,
    //         "kategori_id": 0,
    //         "link": "https://www.modernland.co.id/",
    //         "gambar": "sdsd.JPG",
    //         "type": "Vertikal",
    //         "new_flag": 0,
    //         "created_at": "2021-02-25T07:19:11",
    //         "updated_at": "2021-04-08T11:47:08",
    //         "deleted_at": null
    //     },
    //     {
    //         "id": 39,
    //         "no_urut": 100,
    //         "kategori_id": 0,
    //         "link": "https://serponggarden.com/",
    //         "gambar": "HIPILAND.jpg",
    //         "type": "Horizontal",
    //         "new_flag": 0,
    //         "created_at": "2021-02-26T07:24:20",
    //         "updated_at": "2021-09-09T10:41:04",
    //         "deleted_at": null
    //     },
    //     {
    //         "id": 40,
    //         "no_urut": 100,
    //         "kategori_id": 0,
    //         "link": "https://www.tamansaribintaromansion.com/",
    //         "gambar": "GDDP_BINTARO.png",
    //         "type": "Vertikal",
    //         "new_flag": 0,
    //         "created_at": "2021-02-26T09:01:28",
    //         "updated_at": "2021-04-08T11:47:08",
    //         "deleted_at": null
    //     },
    //     {
    //         "id": 41,
    //         "no_urut": 100,
    //         "kategori_id": 0,
    //         "link": "https://www.mustikaland.co.id/",
    //         "gambar": "mustika_i_village.png",
    //         "type": "Vertikal",
    //         "new_flag": 0,
    //         "created_at": "2021-02-26T09:06:08",
    //         "updated_at": "2021-04-08T11:47:08",
    //         "deleted_at": null
    //     },
    //     {
    //         "id": 42,
    //         "no_urut": 14,
    //         "kategori_id": 0,
    //         "link": "https://yestar.co.id/",
    //         "gambar": "yestar.png",
    //         "type": "Vertikal",
    //         "new_flag": 0,
    //         "created_at": "2021-02-26T09:11:03",
    //         "updated_at": "2023-03-16T13:38:39",
    //         "deleted_at": "2023-03-16T13:38:39"
    //     },
    //     {
    //         "id": 43,
    //         "no_urut": 100,
    //         "kategori_id": 0,
    //         "link": "https://www.mugi.co.id/home-mugi",
    //         "gambar": "mugi_griyatama.jpg",
    //         "type": "Vertikal",
    //         "new_flag": 0,
    //         "created_at": "2021-02-26T09:15:17",
    //         "updated_at": "2022-08-29T09:59:10",
    //         "deleted_at": "2022-08-29T09:59:10"
    //     },
    //     {
    //         "id": 44,
    //         "no_urut": 100,
    //         "kategori_id": 0,
    //         "link": "https://pesonaadibatara.com/",
    //         "gambar": "qweqwe.JPG",
    //         "type": "Vertikal",
    //         "new_flag": 0,
    //         "created_at": "2021-03-03T02:39:45",
    //         "updated_at": "2021-04-08T11:47:08",
    //         "deleted_at": null
    //     },
    //     {
    //         "id": 45,
    //         "no_urut": 100,
    //         "kategori_id": 0,
    //         "link": "http://mutiarakarawaci1.blogspot.com/2010/05/di-jual-rumah-murah-di-tangerang.html",
    //         "gambar": "pasar_mutiara_karawaci.jpg",
    //         "type": "Vertikal",
    //         "new_flag": 0,
    //         "created_at": "2021-03-03T02:54:54",
    //         "updated_at": "2022-08-29T09:59:07",
    //         "deleted_at": "2022-08-29T09:59:07"
    //     },
    //     {
    //         "id": 46,
    //         "no_urut": 100,
    //         "kategori_id": 0,
    //         "link": "https://m.facebook.com/pages/PT-Argopuro-Karya-Kencana-Utama/105016589538289",
    //         "gambar": "arggr.JPG",
    //         "type": "Vertikal",
    //         "new_flag": 0,
    //         "created_at": "2021-03-03T03:06:13",
    //         "updated_at": "2021-04-08T11:47:08",
    //         "deleted_at": null
    //     },
    //     {
    //         "id": 47,
    //         "no_urut": 100,
    //         "kategori_id": 0,
    //         "link": "https://yestar.co.id/",
    //         "gambar": "cilegon_center_mall.JPG",
    //         "type": "Vertikal",
    //         "new_flag": 0,
    //         "created_at": "2021-03-03T03:13:19",
    //         "updated_at": "2021-04-08T11:47:08",
    //         "deleted_at": null
    //     },
    //     {
    //         "id": 48,
    //         "no_urut": 100,
    //         "kategori_id": 0,
    //         "link": "https://www.cileungsigraharaya.com/",
    //         "gambar": "cileungsi_graha_raya.jpg",
    //         "type": "Vertikal",
    //         "new_flag": 0,
    //         "created_at": "2021-03-03T03:57:17",
    //         "updated_at": "2021-04-08T11:47:08",
    //         "deleted_at": "2021-03-08T10:55:10"
    //     },
    //     {
    //         "id": 49,
    //         "no_urut": 4,
    //         "kategori_id": 0,
    //         "link": "http://ancorair.com/",
    //         "gambar": "ancora.jpeg",
    //         "type": "",
    //         "new_flag": 0,
    //         "created_at": "2021-04-08T04:23:47",
    //         "updated_at": "2021-09-09T10:40:09",
    //         "deleted_at": null
    //     },
    //     {
    //         "id": 50,
    //         "no_urut": 5,
    //         "kategori_id": 0,
    //         "link": "https://balinational.com/",
    //         "gambar": "bali-national-golf.png",
    //         "type": "",
    //         "new_flag": 0,
    //         "created_at": "2021-04-08T04:24:32",
    //         "updated_at": "2021-09-09T10:40:14",
    //         "deleted_at": null
    //     },
    //     {
    //         "id": 51,
    //         "no_urut": 100,
    //         "kategori_id": 0,
    //         "link": "https://vcloudproperty.com/",
    //         "gambar": "asana-residence.png",
    //         "type": "",
    //         "new_flag": 0,
    //         "created_at": "2021-04-08T04:25:22",
    //         "updated_at": "2021-04-08T11:47:08",
    //         "deleted_at": "2021-04-08T11:26:04"
    //     },
    //     {
    //         "id": 52,
    //         "no_urut": 12,
    //         "kategori_id": 0,
    //         "link": "https://www.btnproperti.co.id/developer/winland-development-BRD1.html",
    //         "gambar": "winland.jpg",
    //         "type": "",
    //         "new_flag": 0,
    //         "created_at": "2021-04-08T04:29:18",
    //         "updated_at": "2021-09-09T10:40:38",
    //         "deleted_at": null
    //     },
    //     {
    //         "id": 53,
    //         "no_urut": 17,
    //         "kategori_id": 0,
    //         "link": "https://www.marvellcity.com/",
    //         "gambar": "assa-land-surabaya.jpg",
    //         "type": "",
    //         "new_flag": 0,
    //         "created_at": "2021-04-08T04:30:41",
    //         "updated_at": "2021-09-09T10:40:53",
    //         "deleted_at": null
    //     },
    //     {
    //         "id": 54,
    //         "no_urut": 100,
    //         "kategori_id": 0,
    //         "link": "https://www.kallagroup.co.id/",
    //         "gambar": "kalla-group.jpg",
    //         "type": "",
    //         "new_flag": 0,
    //         "created_at": "2021-04-08T04:31:48",
    //         "updated_at": "2021-09-09T10:41:16",
    //         "deleted_at": null
    //     },
    //     {
    //         "id": 55,
    //         "no_urut": 3,
    //         "kategori_id": 0,
    //         "link": "https://urbanheightsresidences.co.id/",
    //         "gambar": "urbanresidences.jpg",
    //         "type": "",
    //         "new_flag": 0,
    //         "created_at": "2021-05-18T03:00:39",
    //         "updated_at": "2023-03-16T13:47:50",
    //         "deleted_at": "2023-03-16T13:47:50"
    //     },
    //     {
    //         "id": 56,
    //         "no_urut": 100,
    //         "kategori_id": 0,
    //         "link": "http://gibland.co.id/",
    //         "gambar": "gibland.png",
    //         "type": "",
    //         "new_flag": 0,
    //         "created_at": "2021-05-18T03:01:34",
    //         "updated_at": "2021-09-09T10:41:37",
    //         "deleted_at": null
    //     },
    //     {
    //         "id": 57,
    //         "no_urut": 2,
    //         "kategori_id": 0,
    //         "link": "https://www.perumbigpol.com/",
    //         "gambar": "KAREUMBI-GROUP.png",
    //         "type": "",
    //         "new_flag": 0,
    //         "created_at": "2021-07-28T08:15:40",
    //         "updated_at": "2021-09-09T10:39:52",
    //         "deleted_at": null
    //     },
    //     {
    //         "id": 58,
    //         "no_urut": 100,
    //         "kategori_id": 0,
    //         "link": "https://amega.id/",
    //         "gambar": "amega.png",
    //         "type": "",
    //         "new_flag": 0,
    //         "created_at": "2021-09-09T03:11:16",
    //         "updated_at": "2023-03-16T14:42:59",
    //         "deleted_at": null
    //     },
    //     {
    //         "id": 59,
    //         "no_urut": 0,
    //         "kategori_id": 0,
    //         "link": "https://www.uic.co.id/",
    //         "gambar": "File_Master_WG_Nov21_(1).jpg",
    //         "type": "",
    //         "new_flag": 0,
    //         "created_at": "2022-01-26T07:51:02",
    //         "updated_at": null,
    //         "deleted_at": null
    //     },
    //     {
    //         "id": 60,
    //         "no_urut": 0,
    //         "kategori_id": 0,
    //         "link": "https://digitalpromo.co.id/webcentralrayagroup/about-us/",
    //         "gambar": "Logo-Central-Group-1024x730.png",
    //         "type": "",
    //         "new_flag": 0,
    //         "created_at": "2022-01-26T07:52:49",
    //         "updated_at": null,
    //         "deleted_at": null
    //     },
    //     {
    //         "id": 61,
    //         "no_urut": 555,
    //         "kategori_id": 0,
    //         "link": "https://www.tangcitymall.com/",
    //         "gambar": "220px-TangCity_Mall_Logo.png",
    //         "type": "",
    //         "new_flag": 1,
    //         "created_at": "2022-01-26T07:55:25",
    //         "updated_at": "2023-03-16T14:17:21",
    //         "deleted_at": null
    //     },
    //     {
    //         "id": 62,
    //         "no_urut": 0,
    //         "kategori_id": 0,
    //         "link": "https://grandoutlets.com/id/",
    //         "gambar": "1597406448822.jpg",
    //         "type": "",
    //         "new_flag": 1,
    //         "created_at": "2023-03-07T16:40:23",
    //         "updated_at": "2023-03-16T14:50:35",
    //         "deleted_at": null
    //     },
    //     {
    //         "id": 63,
    //         "no_urut": 0,
    //         "kategori_id": 0,
    //         "link": "https://www.menaradanareksa.co.id/",
    //         "gambar": "og-facebook.png",
    //         "type": "",
    //         "new_flag": 1,
    //         "created_at": "2023-03-07T16:41:33",
    //         "updated_at": "2023-03-16T14:58:16",
    //         "deleted_at": null
    //     },
    //     {
    //         "id": 64,
    //         "no_urut": 0,
    //         "kategori_id": 0,
    //         "link": "https://olympic-kis.com/",
    //         "gambar": "download.png",
    //         "type": "",
    //         "new_flag": 1,
    //         "created_at": "2023-03-07T16:42:17",
    //         "updated_at": "2023-03-16T14:45:03",
    //         "deleted_at": null
    //     },
    //     {
    //         "id": 65,
    //         "no_urut": 555,
    //         "kategori_id": 0,
    //         "link": "https://kawanagolfresidence.com/",
    //         "gambar": "download_(1).png",
    //         "type": "",
    //         "new_flag": 1,
    //         "created_at": "2023-03-07T16:45:44",
    //         "updated_at": "2023-03-16T14:17:19",
    //         "deleted_at": null
    //     },
    //     {
    //         "id": 66,
    //         "no_urut": 0,
    //         "kategori_id": 0,
    //         "link": "https://pemasaranvilarizkiilhami2.business.site/",
    //         "gambar": "Screenshot_2023-03-16_133330.png",
    //         "type": "",
    //         "new_flag": 0,
    //         "created_at": "2023-03-07T16:46:55",
    //         "updated_at": "2023-03-16T13:36:10",
    //         "deleted_at": null
    //     },
    //     {
    //         "id": 67,
    //         "no_urut": 0,
    //         "kategori_id": 0,
    //         "link": "gardens.id",
    //         "gambar": "Logo-Gardens-PNG-edit1.png",
    //         "type": "",
    //         "new_flag": 0,
    //         "created_at": "2023-03-07T16:47:46",
    //         "updated_at": null,
    //         "deleted_at": null
    //     },
    //     {
    //         "id": 68,
    //         "no_urut": 0,
    //         "kategori_id": 0,
    //         "link": "http",
    //         "gambar": "bali_mitra_investama_pt.jpg",
    //         "type": "",
    //         "new_flag": 0,
    //         "created_at": "2023-03-07T16:48:42",
    //         "updated_at": "2023-03-16T13:55:50",
    //         "deleted_at": "2023-03-16T13:55:50"
    //     },
    //     {
    //         "id": 69,
    //         "no_urut": 0,
    //         "kategori_id": 0,
    //         "link": "http",
    //         "gambar": "sudirman-plaza-jasa-branding-dan-design-jakarta-indonesia-brand-corporate-identity-packaging-digital",
    //         "type": "",
    //         "new_flag": 0,
    //         "created_at": "2023-03-07T16:50:18",
    //         "updated_at": "2023-03-07T23:52:04",
    //         "deleted_at": "2023-03-07T23:52:04"
    //     },
    //     {
    //         "id": 70,
    //         "no_urut": 0,
    //         "kategori_id": 0,
    //         "link": "http",
    //         "gambar": "sudirman-plaza-jasa-branding-dan-design-jakarta-indonesia-brand-corporate-identity-packaging-digital",
    //         "type": "",
    //         "new_flag": 0,
    //         "created_at": "2023-03-07T16:52:22",
    //         "updated_at": "2023-03-07T23:52:34",
    //         "deleted_at": "2023-03-07T23:52:34"
    //     },
    //     {
    //         "id": 71,
    //         "no_urut": 0,
    //         "kategori_id": 0,
    //         "link": "http",
    //         "gambar": "Screenshot_2023-03-07_235334.png",
    //         "type": "",
    //         "new_flag": 0,
    //         "created_at": "2023-03-07T16:54:17",
    //         "updated_at": "2023-03-16T11:43:54",
    //         "deleted_at": "2023-03-16T11:43:54"
    //     },
    //     {
    //         "id": 72,
    //         "no_urut": 555,
    //         "kategori_id": 0,
    //         "link": "https://www.menaradanareksa.co.id/",
    //         "gambar": "Danareksa.png",
    //         "type": "",
    //         "new_flag": 1,
    //         "created_at": "2023-03-09T16:32:38",
    //         "updated_at": "2023-03-16T14:17:27",
    //         "deleted_at": "2023-03-16T11:43:38"
    //     },
    //     {
    //         "id": 73,
    //         "no_urut": 0,
    //         "kategori_id": 0,
    //         "link": "-",
    //         "gambar": "Sudirman_Plaza.png",
    //         "type": "",
    //         "new_flag": 1,
    //         "created_at": "2023-03-09T16:33:54",
    //         "updated_at": "2023-03-16T14:46:42",
    //         "deleted_at": null
    //     },
    //     {
    //         "id": 74,
    //         "no_urut": 555,
    //         "kategori_id": 0,
    //         "link": "https://www.olympic-kis.com/",
    //         "gambar": "Sentul_Industrial.png",
    //         "type": "",
    //         "new_flag": 1,
    //         "created_at": "2023-03-09T16:34:39",
    //         "updated_at": "2023-03-16T14:17:30",
    //         "deleted_at": "2023-03-16T11:44:03"
    //     },
    //     {
    //         "id": 75,
    //         "no_urut": 0,
    //         "kategori_id": 0,
    //         "link": "https://jababekaresidence.com/products/kawana-golf-residence/",
    //         "gambar": "Kawana.png",
    //         "type": "",
    //         "new_flag": 0,
    //         "created_at": "2023-03-09T16:35:24",
    //         "updated_at": "2023-03-09T23:39:28",
    //         "deleted_at": "2023-03-09T23:39:28"
    //     },
    //     {
    //         "id": 76,
    //         "no_urut": 0,
    //         "kategori_id": 0,
    //         "link": "-",
    //         "gambar": "Rizki_Mustika_Abadi.png",
    //         "type": "",
    //         "new_flag": 0,
    //         "created_at": "2023-03-09T16:36:05",
    //         "updated_at": "2023-03-16T11:42:33",
    //         "deleted_at": "2023-03-16T11:42:33"
    //     },
    //     {
    //         "id": 77,
    //         "no_urut": 0,
    //         "kategori_id": 0,
    //         "link": "https://gardens.id/",
    //         "gambar": "Gardens.png",
    //         "type": "",
    //         "new_flag": 0,
    //         "created_at": "2023-03-09T16:36:36",
    //         "updated_at": "2023-03-16T14:22:40",
    //         "deleted_at": "2023-03-16T14:22:40"
    //     },
    //     {
    //         "id": 78,
    //         "no_urut": 0,
    //         "kategori_id": 0,
    //         "link": "-",
    //         "gambar": "Bali_Mitra_Investama.png",
    //         "type": "",
    //         "new_flag": 0,
    //         "created_at": "2023-03-09T16:37:02",
    //         "updated_at": "2023-03-16T11:42:20",
    //         "deleted_at": "2023-03-16T11:42:20"
    //     },
    //     {
    //         "id": 79,
    //         "no_urut": 555,
    //         "kategori_id": 0,
    //         "link": "https://linktr.ee/plazajambuduaofficial",
    //         "gambar": "Screenshot_2023-03-16_133640.png",
    //         "type": "",
    //         "new_flag": 1,
    //         "created_at": "2023-03-16T06:38:15",
    //         "updated_at": "2023-03-16T14:17:31",
    //         "deleted_at": null
    //     },
    //     {
    //         "id": 80,
    //         "no_urut": 555,
    //         "kategori_id": 0,
    //         "link": "https://www.instagram.com/balimitrainvestamagroup",
    //         "gambar": "Screenshot_2023-03-16_134027.png",
    //         "type": "",
    //         "new_flag": 0,
    //         "created_at": "2023-03-16T06:42:07",
    //         "updated_at": "2023-03-16T14:56:16",
    //         "deleted_at": null
    //     }
    // ])
    
    const [logoClientDataDummy, setLogoClientDataDummy] = useState([
        {
            "id": 1,
            "no_urut": 100,
            "kategori_id": 1,
            "link": "",
            "gambar": "ALM.jpg",
            "type": "Vertikal",
            "new_flag": 0,
            "created_at": "2021-02-22T07:30:53",
            "updated_at": "2021-04-08T11:46:50",
            "deleted_at": null
        },
        {
            "id": 2,
            "no_urut": 100,
            "kategori_id": 1,
            "link": "",
            "gambar": "Assa Land.png",
            "type": "Vertikal",
            "new_flag": 0,
            "created_at": "2021-02-22T07:31:17",
            "updated_at": "2021-04-08T11:47:08",
            "deleted_at": null
        },
        {
            "id": 3,
            "no_urut": 100,
            "kategori_id": 1,
            "link": "",
            "gambar": "Assa Land_Marvell city.jpg",
            "type": "Vertikal",
            "new_flag": 0,
            "created_at": "2021-02-22T07:31:58",
            "updated_at": "2021-04-08T11:47:08",
            "deleted_at": null
        },
        {
            "id": 4,
            "no_urut": 15,
            "kategori_id": 1,
            "link": "",
            "gambar": "Discovery Mall Bali.jpeg",
            "type": "Vertikal",
            "new_flag": 0,
            "created_at": "2021-02-22T07:32:12",
            "updated_at": "2023-03-16T13:39:34",
            "deleted_at": "2023-03-16T13:39:34"
        },
        {
            "id": 5,
            "no_urut": 10,
            "kategori_id": 0,
            "link": "",
            "gambar": "Gunacipta Multirasa.png",
            "type": "Vertikal",
            "new_flag": 0,
            "created_at": "2021-02-22T07:32:31",
            "updated_at": "2021-09-09T10:40:32",
            "deleted_at": null
        },
        {
            "id": 6,
            "no_urut": 18,
            "kategori_id": 0,
            "link": "",
            "gambar": "LSPP.png",
            "type": "Vertikal",
            "new_flag": 0,
            "created_at": "2021-02-22T07:32:52",
            "updated_at": "2021-09-09T10:40:56",
            "deleted_at": null
        },
        {
            "id": 7,
            "no_urut": 100,
            "kategori_id": 2,
            "link": "",
            "gambar": "LTC Glodok.png",
            "type": "Vertikal",
            "new_flag": 0,
            "created_at": "2021-02-22T07:40:45",
            "updated_at": "2021-04-08T11:48:55",
            "deleted_at": null
        },
        {
            "id": 8,
            "no_urut": 11,
            "kategori_id": 2,
            "link": "",
            "gambar": "Mangga Dua Square.jpg",
            "type": "Vertikal",
            "new_flag": 0,
            "created_at": "2021-02-22T07:41:20",
            "updated_at": "2021-09-09T10:40:35",
            "deleted_at": null
        },
        {
            "id": 9,
            "no_urut": 100,
            "kategori_id": 2,
            "link": "",
            "gambar": "Mediterania Gajah Mada Residence.jpg",
            "type": "Vertikal",
            "new_flag": 0,
            "created_at": "2021-02-22T07:41:57",
            "updated_at": "2021-04-08T11:47:08",
            "deleted_at": null
        },
        {
            "id": 10,
            "no_urut": 13,
            "kategori_id": 2,
            "link": "",
            "gambar": "Menara Maritim.jpg",
            "type": "Vertikal",
            "new_flag": 0,
            "created_at": "2021-02-22T07:42:08",
            "updated_at": "2021-09-09T10:40:40",
            "deleted_at": null
        },
        {
            "id": 11,
            "no_urut": 100,
            "kategori_id": 0,
            "link": "",
            "gambar": "Modernland Realty Tbk.jpg",
            "type": "Vertikal",
            "new_flag": 0,
            "created_at": "2021-02-22T07:43:02",
            "updated_at": "2021-04-08T11:47:08",
            "deleted_at": null
        },
        {
            "id": 12,
            "no_urut": 100,
            "kategori_id": 3,
            "link": "",
            "gambar": "PCPD (PBI).png",
            "type": "Vertikal",
            "new_flag": 0,
            "created_at": "2021-02-22T07:43:16",
            "updated_at": "2021-04-08T11:47:08",
            "deleted_at": "2021-03-08T11:09:38"
        },
        {
            "id": 13,
            "no_urut": 100,
            "kategori_id": 3,
            "link": "",
            "gambar": "Plaza Jambu Dua Bogor.png",
            "type": "Horizontal",
            "new_flag": 0,
            "created_at": "2021-02-22T07:43:28",
            "updated_at": "2021-04-08T11:47:08",
            "deleted_at": null
        },
        {
            "id": 14,
            "no_urut": 100,
            "kategori_id": 3,
            "link": "",
            "gambar": "PP Urban.png",
            "type": "Vertikal",
            "new_flag": 0,
            "created_at": "2021-02-22T07:43:43",
            "updated_at": "2021-04-08T11:47:08",
            "deleted_at": "2021-03-08T10:47:13"
        },
        {
            "id": 15,
            "no_urut": 9,
            "kategori_id": 4,
            "link": "",
            "gambar": "pp0.png",
            "type": "Horizontal",
            "new_flag": 0,
            "created_at": "2021-02-22T07:44:21",
            "updated_at": "2021-09-09T10:40:28",
            "deleted_at": null
        },
        {
            "id": 16,
            "no_urut": 100,
            "kategori_id": 4,
            "link": "",
            "gambar": "Prima Bangun Investama.png",
            "type": "Horizontal",
            "new_flag": 0,
            "created_at": "2021-02-22T07:44:33",
            "updated_at": "2021-04-08T11:47:08",
            "deleted_at": null
        },
        {
            "id": 17,
            "no_urut": 100,
            "kategori_id": 0,
            "link": "",
            "gambar": "PT Pardika Wisthi Sarana.png",
            "type": "Vertikal",
            "new_flag": 0,
            "created_at": "2021-02-22T07:44:58",
            "updated_at": "2021-09-09T10:41:12",
            "deleted_at": null
        },
        {
            "id": 18,
            "no_urut": 100,
            "kategori_id": 4,
            "link": "",
            "gambar": "Pulo Gadung Trade Center.jpeg",
            "type": "Vertikal",
            "new_flag": 0,
            "created_at": "2021-02-22T07:45:12",
            "updated_at": "2021-04-08T11:47:08",
            "deleted_at": null
        },
        {
            "id": 19,
            "no_urut": 100,
            "kategori_id": 5,
            "link": "",
            "gambar": "Sampoerna Strategic Square.png",
            "type": "Horizontal",
            "new_flag": 0,
            "created_at": "2021-02-22T07:45:50",
            "updated_at": "2021-04-08T11:47:08",
            "deleted_at": null
        },
        {
            "id": 20,
            "no_urut": 100,
            "kategori_id": 5,
            "link": "",
            "gambar": "Southcity.png",
            "type": "Horizontal",
            "new_flag": 0,
            "created_at": "2021-02-22T07:46:03",
            "updated_at": "2021-04-08T11:47:08",
            "deleted_at": null
        },
        {
            "id": 21,
            "no_urut": 100,
            "kategori_id": 5,
            "link": "",
            "gambar": "Surya Cipta Swadaya.png",
            "type": "Horizontal",
            "new_flag": 0,
            "created_at": "2021-02-22T07:46:15",
            "updated_at": "2023-03-09T23:37:49",
            "deleted_at": "2023-03-09T23:37:49"
        },
        {
            "id": 22,
            "no_urut": 16,
            "kategori_id": 5,
            "link": "",
            "gambar": "Tangcity Mall.png",
            "type": "Vertikal",
            "new_flag": 0,
            "created_at": "2021-02-22T07:46:27",
            "updated_at": "2022-08-29T09:59:02",
            "deleted_at": "2022-08-29T09:59:02"
        },
        {
            "id": 23,
            "no_urut": 6,
            "kategori_id": 6,
            "link": "",
            "gambar": "The East Tower.png",
            "type": "Vertikal",
            "new_flag": 0,
            "created_at": "2021-02-22T07:47:32",
            "updated_at": "2021-09-09T10:40:20",
            "deleted_at": null
        },
        {
            "id": 24,
            "no_urut": 100,
            "kategori_id": 6,
            "link": "",
            "gambar": "Wisma Nusantara.png",
            "type": "Vertikal",
            "new_flag": 0,
            "created_at": "2021-02-22T07:47:49",
            "updated_at": "2021-04-26T14:21:47",
            "deleted_at": null
        },
    ])

    // kategori client
    const [kategoriClientData, setKategoriClientData] = useState([])
    const [kategoriClientDataDummy, setKategoriClientDataDummy] = useState([
        {
            "id": 1,
            "kategori": "Landed Housing",
            "created_at": "2021-02-20T08:28:31",
            "updated_at": "2021-02-20T15:32:10",
            "deleted_at": null
        },
        {
            "id": 2,
            "kategori": "Apartement",
            "created_at": "2021-02-20T08:28:43",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 3,
            "kategori": "Mall",
            "created_at": "2021-02-20T08:28:50",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 4,
            "kategori": "Office Tower",
            "created_at": "2021-02-20T08:29:09",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 5,
            "kategori": "Industrial Estate",
            "created_at": "2021-02-20T08:29:27",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 6,
            "kategori": "Mixed Used Development",
            "created_at": "2021-02-20T08:29:45",
            "updated_at": null,
            "deleted_at": null
        }
    ])

    // many client kategori
    const [manyClientKategoriData, setManyClientKategoriData] = useState([])
    const [manyClientKategoriDataDummy, setManyClientKategoriDataDummy] = useState([
        {
            "id": 1,
            "client_id": 1,
            "kategori_id": 1,
            "created_at": "2021-02-25T01:42:01",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 2,
            "client_id": 2,
            "kategori_id": 1,
            "created_at": "2021-02-25T01:42:01",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 3,
            "client_id": 3,
            "kategori_id": 1,
            "created_at": "2021-02-25T01:42:01",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 6,
            "client_id": 6,
            "kategori_id": 1,
            "created_at": "2021-02-25T01:42:01",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 7,
            "client_id": 7,
            "kategori_id": 2,
            "created_at": "2021-02-25T01:42:01",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 8,
            "client_id": 8,
            "kategori_id": 2,
            "created_at": "2021-02-25T01:42:01",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 15,
            "client_id": 15,
            "kategori_id": 4,
            "created_at": "2021-02-25T01:42:01",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 16,
            "client_id": 16,
            "kategori_id": 4,
            "created_at": "2021-02-25T01:42:01",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 17,
            "client_id": 17,
            "kategori_id": 4,
            "created_at": "2021-02-25T01:42:01",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 26,
            "client_id": 26,
            "kategori_id": 6,
            "created_at": "2021-02-25T01:42:01",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 27,
            "client_id": 28,
            "kategori_id": 1,
            "created_at": "2021-02-25T01:42:01",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 29,
            "client_id": 30,
            "kategori_id": 1,
            "created_at": "2021-02-25T06:37:03",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 30,
            "client_id": 31,
            "kategori_id": 1,
            "created_at": "2021-02-25T06:39:23",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 31,
            "client_id": 32,
            "kategori_id": 1,
            "created_at": "2021-02-25T06:48:06",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 32,
            "client_id": 33,
            "kategori_id": 5,
            "created_at": "2021-02-25T07:10:10",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 33,
            "client_id": 34,
            "kategori_id": 1,
            "created_at": "2021-02-25T07:16:41",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 35,
            "client_id": 9,
            "kategori_id": 1,
            "created_at": "2021-02-26T06:29:15",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 36,
            "client_id": 10,
            "kategori_id": 1,
            "created_at": "2021-02-26T06:29:26",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 41,
            "client_id": 40,
            "kategori_id": 2,
            "created_at": "2021-02-26T09:01:28",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 42,
            "client_id": 41,
            "kategori_id": 1,
            "created_at": "2021-02-26T09:06:08",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 45,
            "client_id": 44,
            "kategori_id": 1,
            "created_at": "2021-03-03T02:39:45",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 47,
            "client_id": 46,
            "kategori_id": 1,
            "created_at": "2021-03-03T03:06:13",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 48,
            "client_id": 47,
            "kategori_id": 3,
            "created_at": "2021-03-03T03:13:19",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 50,
            "client_id": 11,
            "kategori_id": 1,
            "created_at": "2021-03-03T04:01:40",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 51,
            "client_id": 29,
            "kategori_id": 5,
            "created_at": "2021-03-04T06:18:55",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 52,
            "client_id": 29,
            "kategori_id": 6,
            "created_at": "2021-03-04T06:18:55",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 53,
            "client_id": 39,
            "kategori_id": 2,
            "created_at": "2021-03-04T10:22:27",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 54,
            "client_id": 39,
            "kategori_id": 6,
            "created_at": "2021-03-04T10:22:27",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 58,
            "client_id": 19,
            "kategori_id": 4,
            "created_at": "2021-03-08T03:49:30",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 59,
            "client_id": 23,
            "kategori_id": 2,
            "created_at": "2021-03-08T03:50:04",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 60,
            "client_id": 20,
            "kategori_id": 1,
            "created_at": "2021-03-08T03:53:49",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 61,
            "client_id": 13,
            "kategori_id": 2,
            "created_at": "2021-03-08T03:54:07",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 63,
            "client_id": 24,
            "kategori_id": 1,
            "created_at": "2021-03-08T04:07:52",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 64,
            "client_id": 25,
            "kategori_id": 4,
            "created_at": "2021-03-08T04:08:13",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 65,
            "client_id": 18,
            "kategori_id": 1,
            "created_at": "2021-03-08T04:10:18",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 68,
            "client_id": 35,
            "kategori_id": 2,
            "created_at": "2021-03-08T06:06:27",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 69,
            "client_id": 49,
            "kategori_id": 1,
            "created_at": "2021-04-08T04:23:47",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 72,
            "client_id": 52,
            "kategori_id": 3,
            "created_at": "2021-04-08T04:29:18",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 73,
            "client_id": 53,
            "kategori_id": 3,
            "created_at": "2021-04-08T04:30:41",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 74,
            "client_id": 54,
            "kategori_id": 2,
            "created_at": "2021-04-08T04:31:48",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 76,
            "client_id": 56,
            "kategori_id": 2,
            "created_at": "2021-05-18T03:01:34",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 77,
            "client_id": 5,
            "kategori_id": 6,
            "created_at": "2021-05-19T02:32:37",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 78,
            "client_id": 57,
            "kategori_id": 1,
            "created_at": "2021-07-28T08:15:40",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 79,
            "client_id": 58,
            "kategori_id": 2,
            "created_at": "2021-09-09T03:11:16",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 80,
            "client_id": 59,
            "kategori_id": 2,
            "created_at": "2022-01-26T07:51:02",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 81,
            "client_id": 60,
            "kategori_id": 6,
            "created_at": "2022-01-26T07:52:49",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 82,
            "client_id": 61,
            "kategori_id": 3,
            "created_at": "2022-01-26T07:55:25",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 83,
            "client_id": 62,
            "kategori_id": 3,
            "created_at": "2023-03-07T16:40:23",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 84,
            "client_id": 63,
            "kategori_id": 4,
            "created_at": "2023-03-07T16:41:33",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 87,
            "client_id": 64,
            "kategori_id": 5,
            "created_at": "2023-03-07T16:44:51",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 89,
            "client_id": 66,
            "kategori_id": 5,
            "created_at": "2023-03-07T16:46:55",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 90,
            "client_id": 67,
            "kategori_id": 5,
            "created_at": "2023-03-07T16:47:46",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 96,
            "client_id": 73,
            "kategori_id": 4,
            "created_at": "2023-03-09T16:33:54",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 102,
            "client_id": 65,
            "kategori_id": 2,
            "created_at": "2023-03-09T16:37:28",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 103,
            "client_id": 50,
            "kategori_id": 6,
            "created_at": "2023-03-09T16:37:39",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 104,
            "client_id": 79,
            "kategori_id": 3,
            "created_at": "2023-03-16T06:38:15",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 105,
            "client_id": 80,
            "kategori_id": 1,
            "created_at": "2023-03-16T06:42:07",
            "updated_at": null,
            "deleted_at": null
        }
    ])

    const handleChange = (event, newAlignment) => {
        setAlignment(newAlignment);
    };

    useEffect(()=>{
        let tempClient = []
        manyClientKategoriDataDummy && manyClientKategoriDataDummy.map((mck) => {
            if (mck.kategori_id === filterClient) {
                tempClient = [...tempClient, mck.client_id]
                setDataClientTemp(tempClient)
            }
        })
    }, [manyClientKategoriDataDummy, filterClient])

    
    const [open, setOpen] = React.useState(false);
    const handleOpen = (props) => setOpen(true);
    const handleClose = (props) => setOpen(false);

    return (
        <div className="client-page container container-fluid">
            <Box
                sx={{
                    width: {
                        xs: '480px',
                        md: '900px',
                        // lg: '1000px'
                    },
                    padding: '0 6px',
                    margin: '80px 0px 24px 0',
                    display: 'flex',
                    justifyContent: 'center !important',
                    alignItems: 'center'
                }}
            >
                <div>
                    <h1 className="text-style-h3 big-margin-top-bottom color-yabes-black">OUR CLIENT</h1>
                    <p className="text-style-h6 big-margin-top-bottom color-yabes-black">We had the pleasure to work with our clients</p>
                    <div className='toggle-filter'>
                        <ToggleButtonGroup
                            color="primary"
                            value={alignment}
                            exclusive
                            onChange={handleChange}
                            aria-label="Platform"
                            orientation={(matches) ? 'horizontal' : 'vertical'}
                        >
                            <ToggleButton className={'toggle-filter-all ' + (filtered ? '' : 'Mui-selected')} onClick={()=>{setFilterClient(0)}} value="All">
                                ALL
                            </ToggleButton>
                            {kategoriClientDataDummy && kategoriClientDataDummy.map((data, idx) => {
                                return (
                                    
                                    <ToggleButton
                                        className='toggle-button-client'
                                        value={data.id}
                                        onClick={()=>{
                                            setFilterClient(data.id)
                                            setFiltered(true)
                                        }}
                                    >
                                        {data.kategori}
                                    </ToggleButton>
                                )
                            })}
                        </ToggleButtonGroup>
                    </div>
                    <Grid container spacing={2}>

                        {
                        (logoClientDataDummy && filtered && filterClient)
                            ? logoClientDataDummy && logoClientDataDummy.map((client, idx) => {
                                return dataClientTemp.map((dataClient)=>{
                                    if (client.id === dataClient) {
                                        return (
                                            <Grid item xs={6} lg={2} sx={{ padding: '12px 0px !important', display: 'flex', justifyContent: 'center' }} className='grid-item-client'>
                                                <Card sx={{ height: '104px', width: '136px', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}  onClick={handleOpen(client)}>
                                                    <img
                                                    // src={`${window.location.origin}/gambar_client/${client.gambar}`}
                                                    src={`${window.location.origin}/gambar_client/logo_client/${client.gambar}`}
                                                    alt="Client Logo"
                                                    className='client-logo img-fluid'
                                                    />
                                                </Card>
                                            </Grid>
                                        )
                                    }
                                })
                            })
                            : logoClientDataDummy && logoClientDataDummy.map((client, idx) => {

                                return (
                                    <Grid item xs={6} lg={2} sx={{ padding: '12px 0px !important', display: 'flex', justifyContent: 'center' }} className='grid-item-client'>
                                        <Card sx={{ height: '104px', width: '136px', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }} onClick={handleOpen}>
                                            <img
                                            src={`${window.location.origin}/gambar_client/logo_client/${client.gambar}`}
                                            alt="Client Logo"
                                            className='client-logo img-fluid'
                                            />
                                        </Card>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </div>
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <DetailClient />
            </Modal>
        </div>
    )
}
export default Client;