import { Button, Grid, TextField } from "@mui/material";
import React, { Component, useEffect, useState } from "react";

import './css/QuestionSection.css'
import './css/TextStyle.css'
import './css/ColorStyle.css'
// import { variables } from "../Variables";

import contactImg from '../assets/Icon/illustration_contact.svg'
import footerTelp from '../assets/Icon/tlpn.png'
import footerWA from '../assets/Icon/whatsapp.svg'
import EmailIcon from '@mui/icons-material/Email';
// import footerEmail from '../assets/img/'

const QuestionSection = () => {
    // var moment = require('moment')
    
    // kontak
    const [kontakData, setKontakData] = useState([])
    const [dayData, setDayData] = useState([])
    const [kontakDataDummy, setKontakDataDummy] = useState([
        {
            "id": 1,
            "email": "sales@yabes.co",
            "tlpn": "021 22637333",
            "fb": "https://www.facebook.com/vcloudproperty",
            "twiter": "https://twitter.com/vcloudproperty",
            "instagram": "https://www.instagram.com/vcloudproperty/",
            "wa": "6287776868717",
            "kalimat_wa": "Halo saya ingin mengetahui info lebih lanjut tentang *Aplikasi vCloudProperty+*",
            "youtube": "https://www.youtube.com/channel/UCuJfPIIQRyn24UOEkrhGXsw",
            "start_day_id": 1,
            "end_day_id": 5,
            "start_hour": "08:30:00",
            "end_hour": "17:30:00",
            "alamat": "Komplek Ruko Duta MerlinBlok E No.38 Jl. Gajah Mada Kav. 3-5 Jakarta Pusat",
            "created_at": "2021-02-25T03:02:06",
            "updated_at": "2023-09-27T14:09:15",
            "deleted_at": null
        }
    ])

    // pesan
    const [pesanName, setPesanName] = useState('')
    const [pesanEmail, setPesanEmail] = useState('')
    const [pesanTelp, setPesanTelp] = useState('')
    const [pesanMessage, setPesanMessage] = useState('')
    // const [pesanCreatedAt, setPesanCreatedAt] = useState(moment().format('YYYY-MM-DDTHH:MM:SS'))
    const [pesanUpdatedAt, setPesanUpdatedAt] = useState(null)
    const [pesanDeletedAt, setPesanDeletedAt] = useState(null)

    // COMMENT TEMPORARY
    // useEffect(() => {
    //     fetch(variables.API_URL+'contact')
    //     .then(response=>response.json())
    //     .then(data=> {
    //         setKontakData(data)
    //     })

    //     fetch(variables.API_URL+'day')
    //     .then(response=>response.json())
    //     .then(data=> {
    //         setDayData(data)
    //     })
        
    // }, [])

    // const submitDataMsg = () => {
    //     fetch(variables.API_URL+'pesan',{
    //         method: 'POST',
    //         headers:{
    //             'Accept': 'application/json',
    //             'Content-Type':'application/json'
    //         },
    //         body: JSON.stringify({
    //             name: pesanName,
    //             email: pesanEmail,
    //             telp: pesanTelp,
    //             message: pesanMessage,
    //             created_at: moment().format('YYYY-MM-DDTHH:MM:SS'),
    //             updated_at: pesanUpdatedAt,
    //             deleted_at: pesanDeletedAt,
    //         })
    //     })
    //     .then(response=>response.json())
    //     .then(result=> {
    //         alert(result)
    //         window.location.reload()
    //     })
    // }

    return (
        <div className="question-section container container-fluid">
            <Grid container spacing={2} className="question-section-grid">
                <Grid item xs={12} md={6} lg={5} className="question-section-grid-item">
                    <img className="img-fluid img-question-section" src={contactImg} />
                </Grid>
                <Grid item xs={12} md={6} lg={4} className="question-section-grid-item">
                    <div className="question-section-form">
                        <div className="questi  on-section-title ">
                            <p className="text-style-h5">Any Question?</p>
                            <p className="text-style-h5">Contact Us Here</p>
                        </div>
                        <TextField
                            required
                            id="outlined-required"
                            fullWidth 
                            variant="outlined"
                            placeholder='Your Full Name Here'
                            label="Fullname"
                            className="text-field"
                            value={pesanName}
                            onChange={(e)=>setPesanName(e.target.value)}
                            sx={{ marginBottom: '18px', backgroundColor: '#fff' }}
                        />
                        <TextField
                            required
                            id="outlined-required"
                            fullWidth 
                            variant="outlined"
                            placeholder='Your Email Address Here'
                            label="Email"
                            className="text-field"
                            value={pesanEmail}
                            onChange={(e)=>setPesanEmail(e.target.value)}
                            sx={{ marginBottom: '18px', backgroundColor: '#fff' }}
                        />
                        <TextField
                            required
                            id="outlined-required"
                            fullWidth 
                            variant="outlined"
                            placeholder='Your Phone Number Here'
                            label="Phone No"
                            className="text-field"
                            value={pesanTelp}
                            onChange={(e)=>setPesanTelp(e.target.value)}
                            sx={{ marginBottom: '18px', backgroundColor: '#fff' }}
                        />
                        <TextField
                            required
                            id="outlined-required"
                            fullWidth 
                            variant="outlined"
                            placeholder='Your Message Here'
                            label="Message"
                            className="text-field"
                            value={pesanMessage}
                            onChange={(e)=>setPesanMessage(e.target.value)}
                            sx={{ marginBottom: '18px', backgroundColor: '#fff' }}
                        />
                        
                        <Button 
                            variant="contained" 
                            className='question-section-button text-style-h6 color-yabes-black'
                            // onClick={()=>submitDataMsg()}
                            sx={{
                                textTransform: 'capitalize'
                            }}
                        >
                            Send Message
                        </Button>
                    </div>
                </Grid>
                <Grid item xs={12} lg={3} className="question-section-grid-item">
                    {kontakDataDummy && kontakDataDummy.length !== 0
                        ? (
                            <div className="question-section-contact">
                                <p className="text-style-h5 color-yabes-black">Contact Info</p>
                                <p className="text-style-h5 color-yabes-black">Yabes Multipro Solusindo</p>
                                <p className="text-style-h6 color-yabes-black" dangerouslySetInnerHTML={{__html: kontakDataDummy[0].alamat.replace(/(\\r\\n|\\n|\\r)/gm,", ")}} />
                                <div className='footer-contact-content'>
                                    {/* <img src={footerTelp} width="18" height="18" className="d-inline-block align-top" alt="" /> */}
                                    <EmailIcon />
                                    <b className="text-style-h6 color-yabes-black"> {kontakDataDummy[0].email}</b>
                                </div>
                                <div className='footer-contact-content'>
                                    <img src={footerTelp} width="18" height="18" className="d-inline-block align-top" alt="" />
                                    <b className="text-style-h6 color-yabes-black"> {kontakDataDummy[0].tlpn}</b>
                                </div>
                                <div className='footer-contact-content'>
                                    <img src={footerWA} width="18" height="18" className="d-inline-block align-top" alt="" />
                                    <b className="text-style-h6 color-yabes-black"> {kontakDataDummy[0].wa}</b>
                                </div>
                                <div>
                                    <a href={`${window.location.origin}/assets/Brosur VP.pdf`} target='_blank'>
                                        <Button 
                                            variant="outlined" 
                                            className='question-section-button text-style-h6 color-yabes-black'
                                            // onClick={()=>submitDataMsg()}
                                            sx={{
                                                textTransform: 'capitalize'
                                            }}
                                        >
                                            Download Brosur
                                        </Button>
                                    </a>
                                </div>
                            </div>
                        )
                        : ''
                    }
                    {/* <div className="question-section-contact">
                        <h2>Contact Info</h2>
                        <h3>Virtual Properti Plus</h3>
                        <p>Komplek Duta Merlin Blok E No. 38 Jalan Gajah Mada Kav 3-5 Petojo Utara, Gambir, Jakarta Pusat DKI Jakarta, Indonesia</p>
                        <div className='footer-contact-content'>
                            <EmailIcon />
                            <b> sales@vcloudproperty.com</b>
                        </div>
                        <div className='footer-contact-content'>
                            <img src={footerTelp} width="18" height="18" className="d-inline-block align-top" alt="" />
                            <b> +62212-2637-333</b>
                        </div>
                        <div className='footer-contact-content'>
                            <img src={footerWA} width="18" height="18" className="d-inline-block align-top" alt="" />
                            <b> +62877-7686-8717</b>
                        </div>
                        <div>
                            <a href={`${window.location.origin}/assets/Brosur VP.pdf`} target='_blank'>
                                <Button variant="outlined" className='question-section-button'>Download Brosur</Button>
                            </a>
                        </div>
                    </div> */}
                </Grid>
            </Grid>
        </div>
    )
}
export default QuestionSection;