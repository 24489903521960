import React from "react";
import { Box } from "@mui/material";

import './About.css'
import './../../components/css/TextStyle.css'
import './../../components/css/ColorStyle.css'


const AboutWhy = () => {

    return(
        <div className="about-why-container">
            <Box
                sx={{
                    width: {
                        xs: '250px',
                        // md: '640px',
                        lg: '800px'
                    },
                }}
            >
                <div className="why-title text-style-h2 text-style-bold color-yabes-black big-margin-top-bottom text-spacing-1">
                    WHY YABES
                </div>
                <div className="why-content">
                    {/* #1 */}
                    <div className="why1-title text-style-h4 text-style-bold color-yabes-gold big-margin-top-bottom text-spacing-1">
                    Commitment
                    </div>
                    <div className="why1-desc text-style-h6 text-align-left color-yabes-black">
                    Our commitments to provide our clients with the best quality systems and customer services have contributed to our success in the software industry. These have also distinguished us from other consultancy and software consultant company.
                    </div>

                    {/* #2 */}
                    <div className="why1-title text-style-h4 text-style-bold color-yabes-gold big-margin-top-bottom text-spacing-1">
                    Project Experience
                    </div>
                    <div className="why1-desc text-style-h6 text-align-left color-yabes-black">
                    Our personnel have extensive computerise project installation and implementation with any complexity of experience. We analyse computerization impact and re-organize personnel job function. In addition, we have acquired a proven project management methodology. It has been our fine reputation that we never failed in any installation/implementation and that is our competitive edge that gives our clients a Peace of mind.
                    </div>

                    {/* #3 */}
                    <div className="why1-title text-style-h4 text-style-bold color-yabes-gold big-margin-top-bottom text-spacing-1">
                    Industrial Knowledge & Accounting Experience
                    </div>
                    <div className="why1-desc text-style-h6 text-align-left color-yabes-black">
                    Our commitments to provide our clients with the best quality systems and customer services have contributed to our success in the software industry. These have also distinguished us from other consultancy and software consultant company.
                    </div>
                    
                    {/* #4 */}
                    <div className="why1-title text-style-h4 text-style-bold color-yabes-gold big-margin-top-bottom text-spacing-1">
                    Version Releases
                    </div>
                    <div className="why1-desc text-style-h6 text-align-left color-yabes-black">
                    The speed of keep improving our software application is very crucial to make sure that we are staying ahead of our competitor in term of the new effective and efficient business process. With nearly 100 installation in the country allowing us to have 2 version releases in one year. All the best process in the property industry and best practices from all of our client and profound knowledge and experience gained from our property consultant and auditing firm collaboration has allowed us to keep incorporating all the best practices in the property industry in to our YABES AdvancedProperty+ Application. Thus make YABES the best property software in the country.
                    </div>

                    {/* #5 */}
                    <div className="why1-title text-style-h4 text-style-bold color-yabes-gold big-margin-top-bottom text-spacing-1">
                    Excellent After Sales Support And Customer Care
                    </div>
                    <div className="why1-desc text-style-h6 text-align-left color-yabes-black">
                    YABES has implemented an integrated CUSTOMER CARE system internally to provide an excellent after sale support to its client that has now being acclaimed the best CUSTOMER CARE System ever implemented in IT Industry.
                    </div>
                </div>
            </Box>
        </div>
    )
}
export default AboutWhy;


