import React, { Component, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Modal from '@mui/material/Modal';

import './css/HomeClient.css'
import './css/TextStyle.css'
import './css/ColorStyle.css'
import { Typography } from '@mui/material';
import DetailClient from './DetailClient';

const HomeClient = (props) => {
    let idxClient = 0
    const filterClient = props && props.filterClient
    const filtered = props && props.filtered
    const matches = props && props.matches
    const logoClientData = props.logoClientData && props.logoClientData
    const dataClientTemp = props.dataClientTemp && props.dataClientTemp
    const kategoriClientData = props.kategoriClientData && props.kategoriClientData
    const manyClientKategoriData = props.manyClientKategoriData && props.manyClientKategoriData

    const handleChange = (event, newAlignment) => {
        props.setAlignment(newAlignment);
    };
    
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    return (
        <div className='our-client container container-fluid'>
            <div className='toggle-filter'>
                <ToggleButtonGroup
                    color="primary"
                    value={props.alignment}
                    exclusive
                    onChange={handleChange}
                    aria-label="Platform"
                    orientation={(matches) ? 'horizontal' : 'vertical'}
                >
                    <ToggleButton className={'toggle-filter-all text-style-h6 ' + (filtered ? '' : 'Mui-selected')} onClick={()=>{props.setFilterClient(0)}} value="All"
                    sx={{
                        textTransform: 'capitalize'
                    }}
                    >
                        All
                    </ToggleButton>
                    {kategoriClientData && kategoriClientData.map((data, idx) => {
                        return (
                            <ToggleButton
                                className='toggle-button-client text-style-h6'
                                value={data.id}
                                onClick={()=>{
                                    props.setFilterClient(data.id)
                                    props.setFiltered(true)
                                }}
                                sx={{
                                    textTransform: 'capitalize'
                                }}
                            >
                                {data.kategori}
                            </ToggleButton>
                        )
                    })}
                </ToggleButtonGroup>
            </div>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={3}>
                    <div className='our-client-sidebar'>
                        <Typography className='our-client-sidebar-title text-style-h2 text-spacing-1 color-yabes-gold'>
                            OUR CLIENT
                        </Typography>
                        <Typography 
                            className='our-client-sidebar-desc text-style-h6 '
                            sx={{
                                margin: '12px 0'
                            }}
                        >
                            We had the pleasure to work with our clients
                        </Typography>
                        <Button 
                                className="CheckButton text-style-p text-style-bold"
                                href='/client'
                                sx={{
                                    backgroundColor: 'rgba(0,0,0,0)',
                                    color: '#BE8C15',
                                    borderRadius: '8px',
                                    padding: '8px 16px',
                                    textTransform: 'none',
                                    marginTop: '8px',
                                    border: '1px solid #BE8C15',
                                    '&:hover': {
                                        backgroundColor: '#000',
                                        border: '1px solid #000',
                                        color: '#fff',
                                    }
                                }}
                            >
                                MORE CLIENT
                            </Button>
                    </div>
                </Grid>
                <Grid item xs={12} lg={9}>
                    <Grid container spacing={2}>

                        {
                        (logoClientData && filtered && filterClient)
                            ? (
                                logoClientData.map((client)=>{
                                    return dataClientTemp.map((dataClient)=>{
                                        if (client.id === dataClient && idxClient < 18) {
                                            idxClient = idxClient + 1
                                            return(
                                                <Grid item xs={6} sm={4} lg={2} className='grid-item-client'>
                                                    <Card sx={{ maxWidth: 345, backgroundColor: '#000', cursor: 'pointer' }} className='grid-item-client-card'  onClick={handleOpen}>
                                                        <img
                                                        src={'/gambar_client/logo_client/'+client.gambar}
                                                        alt="Client Logo"
                                                        className='client-logo img-fluid'
                                                        />
                                                    </Card>
                                                </Grid>
                                            )
                                        }
                                    })
                                })
                            )
                            : logoClientData && logoClientData.slice(0,18).map((client, idx) => {

                                return (
                                    <Grid item xs={6} sm={4} lg={2} className='grid-item-client'>
                                        <Card sx={{ maxWidth: 345, cursor: 'pointer' }}  onClick={handleOpen}>
                                            <img
                                            src={'/gambar_client/logo_client/'+client.gambar}
                                            alt="Client Logo"
                                            className='client-logo img-fluid'
                                            />
                                        </Card>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </Grid>
            </Grid>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <DetailClient />
            </Modal>
        </div>
    )
}
export default HomeClient;


