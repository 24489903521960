import React from "react";
import { Box } from "@mui/material";

import './About.css'
import './../../components/css/TextStyle.css'
import './../../components/css/ColorStyle.css'


const AboutProfile = () => {

    return(
        <div className="about-profile-container">
            <Box
                sx={{
                    width: {
                        xs: '250px',
                        // md: '640px',
                        lg: '800px'
                    },
                }}
            >
                <div className="profile-title text-style-h2 text-style-bold color-yabes-black big-margin-top-bottom text-spacing-1">
                    YABES PROFILE
                </div>
                <div className="profile-content">
                    <div className="profile-desc">
                        <p className="text-style-h6 text-align-left color-yabes-black">
                        PT. Yabes Multipro Solusindo is the best local national company specialises in Property System Software Solution that has been established since 2016. We specialize in the provision of Software Application in the field of Property Development , Tenancy Management, Financial Accounting System and IT networking/hardware solution. Our aim is to deliver a turnkey solution to our prospective clients businesses to help achieving their organization objective.
                        </p>
                        <p className="text-style-h6 text-align-left color-yabes-black">
                        Over the year, we have been growing to become one of the most recognized IT consulting firms in Indonesia, bringing together diversified experience and greatest talents with one focus in mind, serving and providing the highest quality of service to our client to help them achieve their organisational competitive advantage.
                        </p>
                        <p className="text-style-h6 text-align-left color-yabes-black">
                        To-date, YABES has successfully emerged as one of the leading and reputable software consultant company in the following sectors :
                        </p>
                        <ul className="text-style-h6 text-align-left color-yabes-black">
                            <li>Real Estate/Developer Industry</li>
                            <li>Property Management Industry</li>
                            <li>Financial Accounting System</li>
                            <li>Customer Orientation</li>
                            <li>Human Resources System</li>
                            <li>Hardware/Networking Solution</li>
                        </ul>
                        <p className="text-style-h6 text-align-left color-yabes-black">
                        Being developed locally, YABES is able to customize the package to adapt to the ever-increasing demands of the local industry.
                        </p>
                        <p className="text-style-h6 text-align-left color-yabes-black">
                        YABES has also set up a Research And Development Division where all systems research and development work are carried out to continuously enhance and upgrade the system requirements of the selected industries, which the company focus and specialize in best common practice business process in the property industry.
                        </p>
                    </div>
                    <div className="profile-sub-title text-style-h4 text-style-bold color-yabes-gold big-margin-top-bottom text-spacing-1">
                    YABES Business Philosophy
                    </div>
                    <div className="profile-sub-desc">
                        <p className="text-style-h6 text-align-left color-yabes-black">
                        We have assiduously cultivated a philosophy of endeavoring to provide, as a matter of course, Quality with the capital Q in the software that we build as well as in all the related services. In the software industry where customer satisfaction is paramount, we have dedicated ourselves to ensuring that every aspects of our operation is carefully planned and executed so as to fully satisfy our valued customers.
                        </p>
                        <p className="text-style-h6 text-align-left color-yabes-black">
                        We truly feel that the main reason for our growth has been our commitment to our partnerships with our valued customers, focusing on their business, and together creating the best common practice business process in the property industry to help them manage and provide solid foundation to grow their businesses.
                        </p>
                        {/* <p className="text-style-h1 text-align-left color-yabes-black">
                        Ini text varian H1, untuk besarnya mohon diperhatikan. Terima kasih.
                        </p>
                        <p className="text-style-h2 text-align-left color-yabes-black">
                        Ini text varian H2, untuk besarnya mohon diperhatikan. Terima kasih.
                        </p>
                        <p className="text-style-h3 text-align-left color-yabes-black">
                        Ini text varian H3, untuk besarnya mohon diperhatikan. Terima kasih.
                        </p>
                        <p className="text-style-h4 text-align-left color-yabes-black">
                        Ini text varian H4, untuk besarnya mohon diperhatikan. Terima kasih.
                        </p>
                        <p className="text-style-h5 text-align-left color-yabes-black">
                        Ini text varian H5, untuk besarnya mohon diperhatikan. Terima kasih.
                        </p>
                        <p className="text-style-h6 text-align-left color-yabes-black">
                        Ini text varian H6, untuk besarnya mohon diperhatikan. Terima kasih.
                        </p>
                        <p className="text-style-p text-align-left color-yabes-black">
                        Ini text varian p, untuk besarnya mohon diperhatikan. Terima kasih.
                        </p>
                        <p className="text-style-h6 text-align-left color-yabes-black">
                        Discover private and hybrid workspaces to give professionals and business teams an environment where they can perform their best.
                        </p> */}
                    </div>
                </div>
            </Box>
        </div>
    )
}
export default AboutProfile;


