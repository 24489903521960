import React from "react";
import './css/TextStyle.css'
import './css/ColorStyle.css'
// import HomeClient from "../../components/HomeClient";
import product1 from './../assets/Software Solution/property-development.jpg'
import product2 from './../assets/Software Solution/property-management.jpg'
import product3 from './../assets/Software Solution/construction-management.jpg'
import product4 from './../assets/Software Solution/human-resource.jpg'
import product5 from './../assets/Software Solution/financial-accounting.jpg'

import Carousel from 'react-material-ui-carousel'
import { Paper, Button } from '@mui/material'
import Box from '@mui/material/Box';
import { Grid } from "@mui/material";
import imageFeature from './../assets/bg/feature1.jpg'
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const HomeSoftware = () => {
    
    return (
        <div>
            <Box>
                <Typography className="text-style-h2 text-spacing-1 text-style-bold color-yabes-gold">
                    OUR SOLUTION
                </Typography>
            </Box>
            {/* desktop */}
            <Grid container spacing={3} columns={{ xs: 12, sm: 12, md: 12, lg: 10 }} sx={{marginTop:'2px', display: { xs: 'none', sm: 'flex' }, justifyContent:'center'}}>
                {/* <Grid item xs={0} md={1}/> */}
                <Grid item xs={12} sm={4} md={3} lg={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                    {/* 1 */}
                    <Card sx={{ maxWidth: 240, border: '1px solid #A9820F', borderRadius: '18px', backgroundColor: 'rgba(0,0,0,0)' }}>
                        <CardMedia
                            component="img"
                            height="194"
                            image={product1}
                            alt="Paella dish"
                        />
                        <CardContent 
                            sx={{ 
                                padding: '0px !important', 
                                minHeight: '200px'
                            }}
                        >
                            <Box sx={{ padding: '18px 8px 8px 8px' }} minHeight={'120px'}>
                                <Typography sx={{ height: '46px' }} className='text-style-h6 text-style-bold color-yabes-black' >
                                PROPERTY DEVELOPMENT
                                </Typography>
                                <Typography className='text-style-h6' color="text.secondary">
                                AdvancedProperty+ is a fully integrated Property Business Solution designed specifically for the Property industry.
                                </Typography>
                            </Box>
                            {/* <Typography 
                                sx={{ 
                                    padding: '24px 12px', 
                                    background: 'linear-gradient(180deg, rgba(255,239,239,0) 0%, rgba(255,239,239,0.8) 80%, rgba(255,239,239,1) 100%)'
                                    // backgroundColor: 'rgb(250,222,222, 0.48)'
                                }} 
                                className='text-style-h6 color-yabes-black' 
                            >
                                Learn More
                            </Typography> */}
                            <Box
                                sx={{
                                    padding: '24px 18px', 
                                    background: 'linear-gradient(180deg, rgba(255,239,239,0) 0%, rgba(255,239,239,0.8) 80%, rgba(255,239,239,1) 100%)'
                                }}
                            >
                                <Button 
                                    className="text-style-h6"
                                    sx={{
                                        backgroundColor: '#ff7e0e',
                                        border: '2px solid #c95d00',
                                        padding: '2px 4px',
                                        borderRadius: '8px',
                                        color: '#f4f4f4',
                                        '&:hover': {
                                            backgroundColor: '#fcac65',
                                            // color: '#3c52b2',
                                        }
                                    }}
                                >
                                    More
                                </Button>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                
                <Grid item xs={12} sm={4} md={3} lg={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                    {/* 2 */}
                <Card sx={{ maxWidth: 240, border: '1px solid #248fb2', borderRadius: '18px', backgroundColor: 'rgba(0,0,0,0)' }}>
                        <CardMedia
                            component="img"
                            height="194"
                            image={product2}
                            alt="Paella dish"
                        />
                        <CardContent 
                            sx={{ 
                                padding: '0px !important', 
                                minHeight: '200px'
                            }}
                        >
                            <Box sx={{ padding: '18px 8px 8px 8px' }} minHeight={'120px'}>
                                <Typography sx={{ height: '46px' }} className='text-style-h6 text-style-bold color-yabes-black' >
                                PROPERTY MANAGEMENT
                                </Typography>
                                <Typography className='text-style-h6' color="text.secondary">
                                AdvancedProperty+ is a fully integrated Property Business Solution designed specifically for the Property industry.
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    padding: '24px 12px', 
                                    background: 'linear-gradient(180deg, rgba(227,247,252,0) 0%, rgba(227,247,252,0.5) 50%, rgba(227,247,252,1) 100%)'
                                }}
                            >
                                <Button 
                                    className="text-style-h6"
                                    sx={{
                                        backgroundColor: '#ff7e0e',
                                        border: '2px solid #c95d00',
                                        padding: '2px 4px',
                                        borderRadius: '8px',
                                        color: '#f4f4f4',
                                        '&:hover': {
                                            backgroundColor: '#fcac65',
                                            // color: '#3c52b2',
                                        }
                                    }}
                                >
                                    More
                                </Button>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                
                <Grid item xs={12} sm={4} md={3} lg={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                    {/* 3 */}
                    <Card sx={{ maxWidth: 240, border: '1px solid #5d5c99', borderRadius: '18px', backgroundColor: 'rgba(0,0,0,0)' }}>
                        <CardMedia
                            component="img"
                            height="194"
                            image={product5}
                            alt="Paella dish"
                        />
                        <CardContent 
                            sx={{ 
                                padding: '0px !important', 
                                minHeight: '200px'
                            }}
                        >
                            <Box sx={{ padding: '18px 8px 8px 8px' }} minHeight={'120px'}>
                                <Typography sx={{ height: '46px' }} className='text-style-h6 text-style-bold color-yabes-black' >
                                FINANCIAL ACCOUNTING SYSTEM
                                </Typography>
                                <Typography className='text-style-h6' color="text.secondary">
                                AdvancedProperty+ is a fully integrated Property Business Solution designed specifically for the Property industry.
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    padding: '24px 12px', 
                                    background: 'linear-gradient(180deg, rgba(230,230,255,0) 0%, rgba(230,230,255,0.5) 50%, rgba(230,230,255,1) 100%)'
                                }}
                            >
                                <Button 
                                    className="text-style-h6"
                                    sx={{
                                        backgroundColor: '#ff7e0e',
                                        border: '2px solid #c95d00',
                                        padding: '2px 4px',
                                        borderRadius: '8px',
                                        color: '#f4f4f4',
                                        '&:hover': {
                                            backgroundColor: '#fcac65',
                                            // color: '#3c52b2',
                                        }
                                    }}
                                >
                                    More
                                </Button>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                
                <Grid item xs={12} sm={4} md={3} lg={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                    {/* 4 */}
                <Card sx={{ maxWidth: 240, border: '1px solid #004d66', borderRadius: '18px', backgroundColor: 'rgba(0,0,0,0)' }}>
                        <CardMedia
                            component="img"
                            height="194"
                            image={product4}
                            alt="Paella dish"
                        />
                        <CardContent 
                            sx={{ 
                                padding: '0px !important', 
                                minHeight: '200px'
                            }}
                        >
                            <Box sx={{ padding: '18px 8px 8px 8px' }} minHeight={'120px'}>
                                <Typography sx={{ height: '46px' }} className='text-style-h6 text-style-bold color-yabes-black' >
                                HUMAN RESOURCE SOLUTION
                                </Typography>
                                <Typography className='text-style-h6' color="text.secondary">
                                AdvancedProperty+ is a fully integrated Property Business Solution designed specifically for the Property industry.
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    padding: '24px 12px', 
                                    background: 'linear-gradient(180deg, rgba(240,251,255,0) 0%, rgba(240,251,255,0.8) 80%, rgba(240,251,255,1) 100%)'
                                }}
                            >
                                <Button 
                                    className="text-style-h6"
                                    sx={{
                                        backgroundColor: '#ff7e0e',
                                        border: '2px solid #c95d00',
                                        padding: '2px 4px',
                                        borderRadius: '8px',
                                        color: '#f4f4f4',
                                        '&:hover': {
                                            backgroundColor: '#fcac65',
                                            // color: '#3c52b2',
                                        }
                                    }}
                                >
                                    More
                                </Button>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                
                <Grid item xs={12} sm={4} md={3} lg={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                    {/* 5 */}
                    <Card sx={{ maxWidth: 240, border: '1px solid #248fb2', borderRadius: '18px', backgroundColor: 'rgba(0,0,0,0)' }}>
                        <CardMedia
                            component="img"
                            height="194"
                            image={product3}
                            alt="Paella dish"
                        />
                        <CardContent 
                            sx={{ 
                                padding: '0px !important', 
                                minHeight: '200px'
                            }}
                        >
                            <Box sx={{ padding: '18px 8px 8px 8px' }} minHeight={'120px'}>
                                <Typography sx={{ height: '46px' }} className='text-style-h6 text-style-bold color-yabes-black' >
                                CONSTRUCTION MANAGEMENT
                                </Typography>
                                <Typography className='text-style-h6' color="text.secondary">
                                AdvancedProperty+ is a fully integrated Property Business Solution designed specifically for the Property industry.
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    padding: '24px 12px', 
                                    background: 'linear-gradient(180deg, rgba(227,247,252,0) 0%, rgba(227,247,252,0.5) 50%, rgba(227,247,252,1) 100%)'
                                }}
                            >
                                <Button 
                                    className="text-style-h6"
                                    sx={{
                                        backgroundColor: '#ff7e0e',
                                        border: '2px solid #c95d00',
                                        padding: '2px 4px',
                                        borderRadius: '8px',
                                        color: '#f4f4f4',
                                        '&:hover': {
                                            backgroundColor: '#fcac65',
                                            // color: '#3c52b2',
                                        }
                                    }}
                                >
                                    More
                                </Button>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                {/* <Grid item xs={0} md={1}/> */}
            </Grid>

            {/* mobile */}
            <Grid container spacing={3} sx={{marginTop:'2px', display: { xs: 'flex', sm: 'none' }, justifyContent:'center'}}>
                {/* <Grid item xs={0} md={1}/> */}
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    {/* 1 */}
                    <Card sx={{ border: '1px solid #A9820F', borderRadius: '18px', backgroundColor: 'rgba(0,0,0,0)' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={5}>
                                <CardMedia
                                    component="img"
                                    height="100%"
                                    image={product1}
                                    alt="Paella dish"
                                />
                            </Grid>
                            <Grid item xs={7}>
                                <CardContent 
                                    sx={{ 
                                        padding: '0px !important'
                                    }}
                                >
                                    <Box sx={{ padding: '18px 8px 8px 8px', textAlign: 'left' }}>
                                        <Typography sx={{ marginBottom: '8px' }} className='text-style-h5 text-style-bold color-yabes-black' >
                                        Property Development
                                        </Typography>
                                        <Typography className='text-style-h6' color="text.secondary">
                                        YABES Advanced Property helps the developer to manage their project.
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            padding: '24px 8px',
                                            textAlign: 'left' 
                                            // background: 'linear-gradient(180deg, rgba(255,239,239,0) 0%, rgba(255,239,239,0.8) 80%, rgba(255,239,239,1) 100%)'
                                        }}
                                    >
                                        <Button 
                                            className="text-style-h6"
                                            sx={{
                                                backgroundColor: '#ff7e0e',
                                                border: '2px solid #c95d00',
                                                padding: '2px 4px',
                                                borderRadius: '8px',
                                                color: '#f4f4f4',
                                                '&:hover': {
                                                    backgroundColor: '#fcac65',
                                                    // color: '#3c52b2',
                                                }
                                            }}
                                        >
                                            More
                                        </Button>
                                    </Box>
                                </CardContent>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    {/* 2 */}
                    <Card sx={{ border: '1px solid #A9820F', borderRadius: '18px', backgroundColor: 'rgba(0,0,0,0)' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={5}>
                                <CardMedia
                                    component="img"
                                    height="100%"
                                    image={product2}
                                    alt="Paella dish"
                                />
                            </Grid>
                            <Grid item xs={7}>
                                <CardContent 
                                    sx={{ 
                                        padding: '0px !important'
                                    }}
                                >
                                    <Box sx={{ padding: '18px 8px 8px 8px', textAlign: 'left' }}>
                                        <Typography sx={{ marginBottom: '8px' }} className='text-style-h5 text-style-bold color-yabes-black' >
                                        Property Management
                                        </Typography>
                                        <Typography className='text-style-h6' color="text.secondary">
                                        YABES Advanced Property helps the developer to manage their project.
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            padding: '24px 8px',
                                            textAlign: 'left' 
                                            // background: 'linear-gradient(180deg, rgba(255,239,239,0) 0%, rgba(255,239,239,0.8) 80%, rgba(255,239,239,1) 100%)'
                                        }}
                                    >
                                        <Button 
                                            className="text-style-h6"
                                            sx={{
                                                backgroundColor: '#ff7e0e',
                                                border: '2px solid #c95d00',
                                                padding: '2px 4px',
                                                borderRadius: '8px',
                                                color: '#f4f4f4',
                                                '&:hover': {
                                                    backgroundColor: '#fcac65',
                                                    // color: '#3c52b2',
                                                }
                                            }}
                                        >
                                            More
                                        </Button>
                                    </Box>
                                </CardContent>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    {/* 3 */}
                    <Card sx={{ border: '1px solid #A9820F', borderRadius: '18px', backgroundColor: 'rgba(0,0,0,0)' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={5}>
                                <CardMedia
                                    component="img"
                                    height="100%"
                                    image={product5}
                                    alt="Paella dish"
                                />
                            </Grid>
                            <Grid item xs={7}>
                                <CardContent 
                                    sx={{ 
                                        padding: '0px !important'
                                    }}
                                >
                                    <Box sx={{ padding: '18px 8px 8px 8px', textAlign: 'left' }}>
                                        <Typography sx={{ marginBottom: '8px' }} className='text-style-h5 text-style-bold color-yabes-black' >
                                        Financial Accounting System
                                        </Typography>
                                        <Typography className='text-style-h6' color="text.secondary">
                                        YABES Advanced Property helps the developer to manage their project.
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            padding: '24px 8px',
                                            textAlign: 'left' 
                                            // background: 'linear-gradient(180deg, rgba(255,239,239,0) 0%, rgba(255,239,239,0.8) 80%, rgba(255,239,239,1) 100%)'
                                        }}
                                    >
                                        <Button 
                                            className="text-style-h6"
                                            sx={{
                                                backgroundColor: '#ff7e0e',
                                                border: '2px solid #c95d00',
                                                padding: '2px 4px',
                                                borderRadius: '8px',
                                                color: '#f4f4f4',
                                                '&:hover': {
                                                    backgroundColor: '#fcac65',
                                                    // color: '#3c52b2',
                                                }
                                            }}
                                        >
                                            More
                                        </Button>
                                    </Box>
                                </CardContent>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    {/* 4 */}
                    <Card sx={{ border: '1px solid #A9820F', borderRadius: '18px', backgroundColor: 'rgba(0,0,0,0)' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={5}>
                                <CardMedia
                                    component="img"
                                    height="100%"
                                    image={product4}
                                    alt="Paella dish"
                                />
                            </Grid>
                            <Grid item xs={7}>
                                <CardContent 
                                    sx={{ 
                                        padding: '0px !important'
                                    }}
                                >
                                    <Box sx={{ padding: '18px 8px 8px 8px', textAlign: 'left' }}>
                                        <Typography sx={{ marginBottom: '8px' }} className='text-style-h5 text-style-bold color-yabes-black' >
                                        Human Resource Solution
                                        </Typography>
                                        <Typography className='text-style-h6' color="text.secondary">
                                        YABES Advanced Property helps the developer to manage their project.
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            padding: '24px 8px',
                                            textAlign: 'left' 
                                            // background: 'linear-gradient(180deg, rgba(255,239,239,0) 0%, rgba(255,239,239,0.8) 80%, rgba(255,239,239,1) 100%)'
                                        }}
                                    >
                                        <Button 
                                            className="text-style-h6"
                                            sx={{
                                                backgroundColor: '#ff7e0e',
                                                border: '2px solid #c95d00',
                                                padding: '2px 4px',
                                                borderRadius: '8px',
                                                color: '#f4f4f4',
                                                '&:hover': {
                                                    backgroundColor: '#fcac65',
                                                    // color: '#3c52b2',
                                                }
                                            }}
                                        >
                                            More
                                        </Button>
                                    </Box>
                                </CardContent>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    {/* 5 */}
                    <Card sx={{ border: '1px solid #A9820F', borderRadius: '18px', backgroundColor: 'rgba(0,0,0,0)' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={5}>
                                <CardMedia
                                    component="img"
                                    height="100%"
                                    image={product3}
                                    alt="Paella dish"
                                />
                            </Grid>
                            <Grid item xs={7}>
                                <CardContent 
                                    sx={{ 
                                        padding: '0px !important'
                                    }}
                                >
                                    <Box sx={{ padding: '18px 8px 8px 8px', textAlign: 'left' }}>
                                        <Typography sx={{ marginBottom: '8px' }} className='text-style-h5 text-style-bold color-yabes-black' >
                                        Construction Management
                                        </Typography>
                                        <Typography className='text-style-h6' color="text.secondary">
                                        YABES Advanced Property helps the developer to manage their project.
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            padding: '24px 8px',
                                            textAlign: 'left' 
                                            // background: 'linear-gradient(180deg, rgba(255,239,239,0) 0%, rgba(255,239,239,0.8) 80%, rgba(255,239,239,1) 100%)'
                                        }}
                                    >
                                        <Button 
                                            className="text-style-h6"
                                            sx={{
                                                backgroundColor: '#ff7e0e',
                                                border: '2px solid #c95d00',
                                                padding: '2px 4px',
                                                borderRadius: '8px',
                                                color: '#f4f4f4',
                                                '&:hover': {
                                                    backgroundColor: '#fcac65',
                                                    // color: '#3c52b2',
                                                }
                                            }}
                                        >
                                            More
                                        </Button>
                                    </Box>
                                </CardContent>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                
                {/* <Grid item xs={0} md={1}/> */}
            </Grid>
        </div>
    )
}
export default HomeSoftware;