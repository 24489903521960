import logo from './logo.svg';
import logoYabes from './assets/logo-yabes-gold.png'
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from 'react-router-dom';
// import moment from 'moment';
import { FloatingWhatsApp } from 'react-floating-whatsapp'

import './App.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Homepage from './pages/home-page/Homepage';
import AboutProfile from './pages/about-page/AboutProfile';
import AboutVisiMisi from './pages/about-page/AboutVisiMisi';
import AboutCSF from './pages/about-page/AboutCSF';
import AboutWhy from './pages/about-page/AboutWhy';
import AboutProcessModel from './pages/about-page/AboutProcessModel';
import Client from './pages/client-page/Client';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        {/* <ValidateAuth /> */}
        <Routes>
          {/* <Route path='/' element={<Login />} /> */}
          <Route path='/' element={<Homepage />} />
          <Route path='/about-profile' element={<AboutProfile />} />
          <Route path='/about-visi-misi' element={<AboutVisiMisi />} />
          <Route path='/about-csf' element={<AboutCSF />} />
          <Route path='/about-why' element={<AboutWhy />} />
          <Route path='/about-process-model' element={<AboutProcessModel />} />
          <Route path='/client' element={<Client />} />
          {/* <Route path='/siteplan' element={<Siteplan />} />
          <Route path='/attachment' element={<Attachment />} />
          <Route path='/unit-mapper/:entityCd/:projectNo/:clusterCode' element={<UnitMapper />} />
          <Route path='/cluster-mapper' element={<ClusterMapper />} /> */}
          {/* <Route path='/example' element={<LayoutExample />} /> */}
        </Routes>
        <Footer />
      </div>
      <FloatingWhatsApp
        phoneNumber="6287776868717"
        accountName="Yabes Co"
        allowEsc
        statusMessage='Property System Software Solution in Jakarta, Indonesia'
        avatar={logoYabes}
        allowClickAway
        notification
        notificationSound
        chatMessage='Halo ada yang bisa saya bantu ?'
        placeholder='Halo saya ingin mengetahui info lebih lanjut tentang *Aplikasi vCloudProperty+*'
      />
    </Router>
  );
}

export default App;
