import React from "react";
import { Box } from "@mui/material";

import './About.css'
import processModel from './../../assets/Icon/processModel.jpg'

const AboutProcessModel = () => {
    return (
        <div className="about-process-model-container">
            <Box
                sx={{
                    width: {
                        xs: '250px',
                        // md: '640px',
                        lg: '800px'
                    },
                }}
            >
                <div className="profile-title text-style-h2 text-style-bold color-yabes-black big-margin-top-bottom text-spacing-1">
                    PROCESS MODEL
                </div>
                <div className="profile-content">
                    <div className="value-image">
                        <img src={processModel} />
                    </div>
                    <div className="value-title text-style-h5 text-align-left text-style-bold color-yabes-gold big-margin-top-bottom text-spacing-1">
                    4 Phases
                    </div>
                    <div className="value-content">
                        <ul className="text-style-h6 text-align-left color-yabes-black">
                            <li>Envisioning</li>
                            <li>Planning</li>
                            <li>Developing</li>
                            <li>Stablizing</li>
                        </ul>
                    </div>
                </div>
            </Box>
        </div>
    )
}
export default AboutProcessModel;
