import React from "react";
import { useScrollTrigger } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import './css/Footer.css'
import './css/TextStyle.css'
import './css/ColorStyle.css'
// import yabesLogo from '../assets/logo-yabes-blue.png'
import yabesLogo from '../assets/logo-yabes-org.png'
import vdpLogo from '../assets/Logo-VDP.png'
import ScrollHandler from "./ScrollHandler";
import { Grid } from "@mui/material";

const Footer = () => {
    // const triggerScroll = useScrollTrigger({
    //     disableHysteresis: true,
    //     threshold: 0,
    //     target: props.window ? window() : undefined
    //   });

    return(
        <div className="footer-container">
            <Box 
                sx={{ 
                    display: 'block', 
                    margin: '32px 0 64px 0', 
                    // padding: '0px 18px',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Grid container spacing={2}>
                    <Grid
                        item
                        xs={12}
                        sx={{
                            width: {
                                xs: '640px',
                                md: '800px',
                                lg: '1200px'
                            },
                            padding: '0',
                            display: 'flex',
                            justifyContent: 'center',
                            backgroundColor: '#f4f4f4',
                            padding: '32px 0 64px 0 !important'
                        }}       
                    >
                        <Box
                            sx={{
                                textAlign: 'left',
                                width: {
                                    xs: '640px',
                                    md: '800px',
                                    lg: '1200px'
                                },
                            }}
                        >

                            <img src={yabesLogo} width={'90px'} />
                            {/* <img src={vdpLogo} width={'90px'} /> */}
                            <p className="text-style-h3 text-style-bold color-yabes-blue">
                                PT. YABES MULTIPRO SOLUSINDO
                            </p>
                            <p className="text-style-h6 color-yabes-black">
                                Komplek Ruko Duta MerlinBlok E No.38 Jl. Gajah Mada Kav. 3-5 Jakarta Pusat
                            </p>
                            <p className="text-style-h6 color-yabes-black">
                                sales@yabes.co
                            </p>
                            <p className="text-style-h6 color-yabes-black">
                                021 22637333
                            </p>
                            <p className="text-style-h6 color-yabes-black">
                                6287776868717
                            </p>
                        </Box>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sx={{
                            width: {
                                xs: '640px',
                                md: '800px',
                                lg: '1200px'
                            }, 
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <div>
                            <div className="divider-footer"></div>
                            <p className="text-style-p color-light-black text-align-center big-margin-top-bottom">
                                © Copyright 2023 | PT. YABES MULTIPRO SOLUSINDO | All Rights Reserved
                            </p>
                        </div>
                    </Grid>
                </Grid>
            </Box>

        </div>
    )
}
export default Footer;